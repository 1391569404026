import { v4 as uuidv4 } from 'uuid';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { Sprite } from './sprite';

interface Props {
  label: string | ReactNode;
  errorMessage?: string;
  defaultValue?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onToggle?: (checked: boolean) => void;
}

export const Checkbox: React.FC<Props> = React.forwardRef<
  HTMLInputElement,
  Props
>(
  (
    { label, errorMessage, defaultValue, onChange, onToggle, ...props },
    ref
  ) => {
    const id = useMemo(
      () => `${label?.toString().toLowerCase()}-${uuidv4()}`,
      [label]
    );
    const [checked, setChecked] = useState<boolean>(defaultValue || false);

    const handleOnChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(event);
        }
        const { checked: isChecked } = event.currentTarget;
        setChecked(isChecked);
        if (onToggle) {
          onToggle(isChecked);
        }
      },
      [setChecked, onChange, onToggle]
    );

    return (
      <label htmlFor={id}>
        <div className="flex flex-row">
          <div>
            <div className="border border-black border-2 rounded-[5px] w-[30px] h-[30px] cursor-pointer bg-white">
              <div
                className="bg-red flex flex-row justify-center items-center w-full h-full transition-opacity duration-150 rounded-[5px]"
                style={{
                  opacity: checked ? 1 : 0,
                }}
              >
                <Sprite name="check" className="w-[10px] h-[8px]" />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center h-full pl-2">
            <h4 className="text-black font-inter">{label}</h4>
          </div>
        </div>
        <input
          className="hidden"
          ref={ref}
          type="checkbox"
          defaultValue={defaultValue?.toString()}
          id={id}
          onChange={handleOnChange}
          {...props}
        />
        {errorMessage && (
          <div className="text-error text-xs">{errorMessage}</div>
        )}
      </label>
    );
  }
);

Checkbox.defaultProps = {
  defaultValue: false,
  onChange: undefined,
  onToggle: undefined,
  errorMessage: '',
};
