import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { axiosInstance } from '../utils/axios-utils';
import { errorHandler } from '../utils/errorHandler';
import { StateLoading } from '../utils/types';
import * as Sentry from "@sentry/browser";

interface ServerContact {
  phone: string;
  email: string;
  emailSubject: string;
  website: string;
  bookUrl: string;
  schedulingLink: string;
  cancelSubscrptionEmailSubject: string;
  privacyUrl: string;
}

interface ServerConfig {
  serverStarted: Date;
  isMobileMaintenance: boolean;
  contact: ServerContact;
  termsAndCondition: string;
}

interface ConfigState {
  serverConfig?: StateLoading<ServerConfig>;
}

interface ConfigAction {
  getServerConfig: () => Promise<void>;
}

const useConfigStore = create<ConfigState & ConfigAction>()(
  devtools(
    persist(
      (set) => ({
        getServerConfig: async () => {
          set({ serverConfig: { isLoading: true, error: undefined } });
          try {
            const { data } = await axiosInstance({ url: '/' });
            set({ serverConfig: { isLoading: false, data } });
          } catch (err) {
            Sentry.captureException(err);

            set({
              serverConfig: { isLoading: false, error: errorHandler(err) },
            });
          }
        },
      }),
      {
        name: 'configStorage',
      }
    )
  )
);

export default useConfigStore;
