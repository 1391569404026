import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMobile } from '../hooks/useMobile';
import { useCheckoutStore } from '../store/checkout-store';
import { useSubdomainStore } from '../store/subdomain-store';
import { useGiftingStore } from '../store/gifting-store';

interface Props {
  children: React.ReactNode;
  enable?: boolean;
}

/**
 * Makes sure that the slug is defined, else a 401 is assumed.
 */
export const SlugProvider: React.FC<Props> = ({ children, enable }: Props) => {
  const navigate = useNavigate();
  const isFromMobile = useMobile();

  const { validGiftCode } = useGiftingStore();
  const slug = useCheckoutStore((state) => (isFromMobile ? 'standard' : state.slug));
  const { isOnsite } = useSubdomainStore();

  useEffect(() => {
    if (!enable) {
      return;
    }
    if (!slug && !isOnsite) {
      const { pathname } = window.location;
      if (pathname !== '/subscription-success' && !validGiftCode && pathname !== '/buy-gift-success' && pathname !== '/sign-up') {
        navigate('/401');
      }
    }
  }, [slug]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

SlugProvider.defaultProps = {
  enable: true,
};
