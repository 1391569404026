import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthStore } from '../store/auth-store';
import { Button } from './button';
import { InputField } from './input-field';
import * as Sentry from "@sentry/browser";


type Input = {
  code: string
}

export const VerificationForm: React.FC = () => {
  const verifyCode = useAuthStore((state) => state.verifyCode);
  const verifyCodeError = useAuthStore((state) => state.verifyCodeError);
  const email = useAuthStore((state) => state.email);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const {
    register, handleSubmit, formState: { errors, isSubmitting },
  } = useForm<Input>();

  const onSubmit = async (data: Input) => {
    try {
      await verifyCode({
        email,
        code: data.code,
      });
      setSubmitted(true);
    } catch (error) {
      Sentry.captureException(error);

    }
  };

  const loading = useMemo(() => isSubmitting || submitted, [isSubmitting, submitted]);
  const requiredFieldErrorMessage = 'This field is required.';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {verifyCodeError && <p className="text-red mb-2">{verifyCodeError}</p>}
      <fieldset>
        <InputField
          label="6-digit code"
          type="text"
          errorMessage={errors.code?.message}
          {...register('code', {
            required: requiredFieldErrorMessage,
          })}
        />
      </fieldset>
      <fieldset className="flex flex-row justify-end mt-6">
        <Button
          className="flex-1"
          type="submit"
          loading={loading}
        >
          {loading ? 'Loading' : 'Send'}
        </Button>
      </fieldset>
    </form>
  );
};
