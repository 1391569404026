import axios from 'axios';
import type { AxiosInstance } from 'axios';

import { RedeemGiftCodeInput, GetGiftsInput } from './types';

export class GiftsService {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: import.meta.env.VITE_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async getGiftByCode(input: RedeemGiftCodeInput) {
    const response = await this.instance.get(`/gifts/code/${input.giftCode}?dbOnly=true`);
    return response.data;
  }

  async validateGiftCode(input: RedeemGiftCodeInput) {
    const response = await this.instance.get(`gifts/validate-code/${input.giftCode}`);
    return response.data;
  }

  async getGifts(input: GetGiftsInput) {
    const response = await this.instance.get(
      `gifts/my-gifts/${input.email}`,
      { headers: { Authorization: `Bearer ${input.accessToken}`, } }
    );
    return response.data;
  }
}
