import { BrowserRouter, Link, Outlet, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import Error401Page from './pages/401';
import Error404Page from './pages/404';
import AlreadySubscribedPage from './pages/already-subscribed';
import ForgotPasswordPage from './pages/forgot-password';
import RedeemGiftPage from './pages/redeem-gift';
import LandingPage from './pages/landing';
import LoginPage from './pages/login';
import ProductNotAvailablePage from './pages/product-not-available';
import RedirectingPage from './pages/redirecting';
import RedirectingPageGiftCart from './pages/redirecting-gift-cart';
import RegistrationPage from './pages/onsite-registration';
import ResetPasswordPage from './pages/reset-password';
import AccountDeletionPage from './pages/delete-account';
import SignUpPage from './pages/sign-up';
import SuccessRegistration from './pages/success-registration';
import SuccessGiftOrder from './pages/success-gift-order';
import SuccessResetPasswordPage from './pages/success-reset-password';
import SessionExpiredPage from './pages/session-expired';
import VerifyPage from './pages/verify';
import { CheckoutProvider } from './providers/checkout-provider';
import { SlugProvider } from './providers/slug-provider';
import { Layout } from './components/layout';
import { Image } from './components/image';
import { ModeProvider } from './providers/mode-provider';
import ComponentsPage from './pages/components';
import { useSubdomainStore } from './store/subdomain-store';
import SuccessRegistrationGift from './pages/success-gift-registration';

function Router() {
  const onsiteDefaultSubdomain: string = import.meta.env.VITE_ONSITE_SUBDOMAIN;
  const { isOnsite, setIsOnsite } = useSubdomainStore();

  useEffect(() => {
    const { host } = window.location;
    const subdomain = host.split('.')[0];
    if (subdomain === onsiteDefaultSubdomain) {
      setIsOnsite(true);
    }
  }, [setIsOnsite]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ModeProvider>
              <Outlet />
            </ModeProvider>
          }
        >
          {isOnsite ? (
            <Route
              path="/"
              element={
                <SlugProvider enable>
                  <Outlet />
                </SlugProvider>
              }
            >
              <Route
                path="/"
                element={
                  <Layout bannerContent={<Image src="/sign-up-splash.jpg" />}>
                    <Outlet />
                  </Layout>
                }
              >
                <Route path="/registration" element={<RegistrationPage />} />
                <Route
                  path="/registration/:slug"
                  element={<RegistrationPage />}
                />
                <Route
                  path="/registration-success"
                  element={<SuccessRegistration />}
                />
              </Route>
            </Route>
          ) : (
            <Route
              path="/"
              element={
                <CheckoutProvider>
                  <Outlet />
                </CheckoutProvider>
              }
            >
              <Route path="/product/:slug/" element={<LandingPage />} />
              <Route
                path="/product/gift"
                element={<RedirectingPageGiftCart />}
              />
              <Route
                path="/"
                element={
                  <SlugProvider enable>
                    <Outlet />
                  </SlugProvider>
                }
              >
                <Route
                  path="/"
                  element={
                    <Layout
                      bannerContent={<Image src="/login-splash.jpeg" />}
                      mobileFooterContent={
                        <>
                          <span className="text-gray-medium font-medium">
                            Don&#39;t have an account?
                          </span>
                          <span className="pl-2">
                            <Link
                              to="/sign-up"
                              className="text-blue font-medium"
                            >
                              Sign Up
                            </Link>
                          </span>
                        </>
                      }
                    >
                      <Outlet />
                    </Layout>
                  }
                >
                  <Route path="/login" element={<LoginPage />} />
                  <Route
                    path="/forgot-password"
                    element={<ForgotPasswordPage />}
                  />
                  <Route
                    path="/reset-password"
                    element={<ResetPasswordPage />}
                  />
                  <Route
                    path="/reset-password-success"
                    element={<SuccessResetPasswordPage />}
                  />
                  <Route
                    path="/reset-password-success"
                    element={<SuccessResetPasswordPage />}
                  />
                </Route>
                <Route
                  path="/"
                  element={
                    <Layout
                      bannerContent={<Image src="/sign-up-splash.jpg" />}
                      mobileFooterContent={
                        <>
                          <span className="text-gray-medium font-medium">
                            Have an account already?
                          </span>
                          <span className="pl-2">
                            <Link to="/login" className="text-blue font-medium">
                              Log in
                            </Link>
                          </span>
                        </>
                      }
                    >
                      <Outlet />
                    </Layout>
                  }
                >
                  <Route
                    path="/subscription-success"
                    element={<SuccessRegistration />}
                  />
                  <Route
                    path="/buy-gift-success"
                    element={<SuccessGiftOrder />}
                  />
                  <Route path="/sign-up" element={<SignUpPage />} />
                  <Route path="/verify-code" element={<VerifyPage />} />
                </Route>
              </Route>
              <Route
                path="/"
                element={
                  <Layout>
                    <Outlet />
                  </Layout>
                }
              >
                <Route path="/redirect" element={<RedirectingPage />} />
                <Route
                  path="/already-subscribed"
                  element={<AlreadySubscribedPage />}
                />
                <Route
                  path="/session-expired"
                  element={<SessionExpiredPage />}
                />
                <Route
                  path="/product-not-available"
                  element={<ProductNotAvailablePage />}
                />
              </Route>
            </Route>
          )}
          <Route path="/components" element={<ComponentsPage />} />
          <Route path="/redeem-gift" element={<RedeemGiftPage />} />
          <Route path="/send-gift" element={<RedeemGiftPage />} />
          <Route
            path="/register-gift-success"
            element={<SuccessRegistrationGift />}
          />
          <Route path="/*" element={<Error404Page />} />
          <Route path="/401" element={<Error401Page />} />
        </Route>
        <Route
          path="/delete-account"
          element={
            <Layout bannerContent={<Image src="/sign-up-splash.jpg" />}>
              <AccountDeletionPage />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
