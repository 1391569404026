import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useModeStore } from '../store/mode-store';

interface Props {
  children: React.ReactNode
}

export const ModeProvider: React.FC<Props> = ({ children }) => {
  const { search } = useLocation();
  const { setMode } = useModeStore();

  useEffect(() => {
    const mode = new URLSearchParams(search)?.get('from');
    if (mode) {
      setMode(mode);
    }
  }, [setMode, search]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
