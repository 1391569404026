// @ts-nocheck
import React, { useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { RedeemGiftCodeInput } from '../services/gifting/types';
import { Button } from './button';
import { InputField } from './input-field';
import { errorHandler } from '../utils/errorHandler';
import { useGiftingStore } from '../store/gifting-store';
import * as Sentry from "@sentry/browser";

const initialButtonStyle = {
  background: 'linear-gradient(90deg, #7B3A4A 0%, #AB3D4F 51.89%, #FF9255 100%)',
  fontSize: '12px',
  fontWeight: '600',
};

const initialFormColumnStyle = {
  flex: '1 1 0%',
};

const initialStyles = {
  buttonStyle: initialButtonStyle,
  formColumnStyle: initialFormColumnStyle,
};

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
}

export const GiftCodeForm: React.FC = () => {
  const navigate = useNavigate();
  const redeemGiftCode = useGiftingStore((state) => state.redeemGiftCode);
  const redeemGiftCodeError = useGiftingStore((state) => state.redeemGiftCodeError);
  const validGiftCode = useGiftingStore((state) => state.validGiftCode);
  const [success, setSuccess] = useState<boolean>(false);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const giftCode = searchParams.get('giftCode') || '';

  const [screenWidth, setScreenWidth] = useState('wide');
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [styles, setStyles] = useState(initialStyles);
  const [isMobileApp, setIsMobileApp] = useState(false);

  const {
    register, handleSubmit, formState: { errors, isSubmitting },
  } = useForm<RedeemGiftCodeInput>();

  useEffect(() => {
    setIsMobileApp(window.isMobileApp === true);
  });

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    setScreenWidth((screenSize.width < 1194 || isMobileApp) ? 'narrow' : 'wide');
    return (() => {
      window.removeEventListener('resize', updateDimension);
    });
  }, [screenSize]);

  useEffect(() => {
    if (screenWidth === 'narrow') {
      setStyles(
        { ...styles,
          buttonStyle: {
            ...styles.buttonStyle,
            // background: 'yellow',
          },
          formColumnStyle: {
            ...styles.formColumnStyle,
            flex: '2 1 0%',
          }
        }
      );
    } else if (screenWidth === 'wide') {
      setStyles(
        { ...styles,
          buttonStyle: initialButtonStyle,
          formColumnStyle: initialFormColumnStyle,
        },
      );
    }
  }, [screenWidth]);

  useEffect(() => {
    if (validGiftCode) {
      setSuccess(true);
      navigate(`/sign-up?giftCode=${validGiftCode}`);
    }
  }, [validGiftCode]);

  const onSubmit = async (data: RedeemGiftCodeInput) => {
    try {
      await redeemGiftCode({
        giftCode: data.giftCode,
      });
      if (validGiftCode) {
        setSuccess(true);
        navigate(`/sign-up?giftCode=${validGiftCode}`);
      }
    } catch (err) {
      const error = err as Error & { status?: string };
      const errMessage = errorHandler(error);
      console.error(errMessage);
      Sentry.captureException(err)
    }
    if (validGiftCode) {
      setSuccess(true);
      navigate(`/sign-up?giftCode=${validGiftCode}`);
    }
  };
  const requiredFieldErrorMessage = 'This field is required';
  const loading = useMemo(
    () => isSubmitting || success,
    [isSubmitting, success],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* {redeemGiftCodeError && <ErrorMessage>{redeemGiftCodeError}</ErrorMessage>} */}
      <fieldset className={screenWidth === 'wide' ? 'mb-8 flex flex-row' : 'flex-column'}>
        <div className="flex flex-col flex-6" style={styles.formColumnStyle}>
          <InputField
            label="Enter Gift Code"
            type="text"
            errorMessage={errors.giftCode?.message || redeemGiftCodeError}
            {...register('giftCode', {
              required: requiredFieldErrorMessage,
            })}
            style={{ borderColor: '#C2C2C2' }}
            defaultValue={giftCode}
          />
        </div>
        <div className={`flex-col flex flex-2 ${screenWidth === 'wide' ? 'pl-3' : 'pt-3'}`}>
          <Button
            type="submit"
            loading={loading}
            style={styles.buttonStyle}
          >
            {loading ? 'Loading' : 'SUBMIT & SIGN-UP'}
          </Button>
        </div>
      </fieldset>
    </form>
  );
};
