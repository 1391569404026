import create from 'zustand';
import { AuthService } from '../services/auth/service';
import type {
  CodeVerificationInput,
  ResendCodeInput,
  SignInInput,
  SignInOutput,
  SignUpInput,
  InitiateResetPasswordInput,
  ValidateEmailInput,
  OnsiteSignUpInput,
} from '../services/auth/types';
import { stringifyAxiosError } from '../utils/axios-utils';
import * as Sentry from "@sentry/browser";


const USE_LOCALSTORAGE_FOR_AUTH = Boolean(JSON.parse(import.meta.env.VITE_USE_LOCALSTORAGE_FOR_AUTH || 'false'));
const authService = new AuthService();

interface AuthState extends SignInOutput {
  email: string
  password: string
  forgotPasswordEmail: string
  signInError: string
  signUpError: string
  verifyCodeError: string
  initiatePasswordResetError: string
  confirmPasswordResetError: string
  validateEmailError: string
  signIn: {(input: SignInInput): Promise<void>}
  signUp: {(input: SignUpInput): Promise<void>}
  onsiteSignUp: {(input: OnsiteSignUpInput): Promise<void>}
  verifyCode: {(input: CodeVerificationInput): Promise<void>}
  resendCode: {(input: ResendCodeInput): Promise<void>}
  initiatePasswordReset: {(input: InitiateResetPasswordInput): Promise<void>}
  confirmPasswordReset: {(input: {code: string, newPassword: string}): Promise<void>}
  validateEmail: {(input: ValidateEmailInput): Promise<void>}
}

const uncachedEmptyState = {
  password: '', // not saved in localStorage and should stay that way
  verifyCodeError: '',
  signInError: '',
  signUpError: '',
  initiatePasswordResetError: '',
  confirmPasswordResetError: '',
  validateEmailError: '',
};

const emptyState = {
  id: '',
  email: '',
  accessToken: '',
  forgotPasswordEmail: '',
  ...uncachedEmptyState,
};
let initialState = emptyState;
if (USE_LOCALSTORAGE_FOR_AUTH) {
  const rawStoredState = localStorage.getItem('customerAuthState');
  if (rawStoredState) {
    initialState = {
      ...JSON.parse(rawStoredState),
      ...uncachedEmptyState,
    };
  }
}

export const useAuthStore = create<AuthState>((set, get) => ({
  ...initialState,
  signIn: async (input) => {
    let auth: SignInOutput;
    try {
      auth = await authService.signIn(input);
    } catch (error) {
      Sentry.captureException(error);

      // eslint-disable-next-line max-len
      set(() => ({ email: input.email, password: input.password, signInError: stringifyAxiosError(error) }));
      throw error;
    }
    const state = {
      id: auth.id,
      email: input.email,
      accessToken: auth.accessToken,
    };
    if (USE_LOCALSTORAGE_FOR_AUTH) {
      localStorage.setItem('customerAuthState', JSON.stringify(state));
    }
    set(() => ({
      ...state,
      signInError: '',
    }));
  },
  signUp: async (input) => {
    try {
      await authService.signUp(input);
    } catch (error) {
      Sentry.captureException(error);

      set(() => ({ signUpError: stringifyAxiosError(error) }));
      throw error;
    }
    set(() => ({
      email: input.email,
      // temporarily persist password in state only to sign in user immediately after verification
      password: input.password,
      signUpError: '',
    }));
  },
  onsiteSignUp: async (input) => {
    try {
      await authService.onsiteSignUp(input);
    } catch (error) {
      Sentry.captureException(error);

      set(() => ({ signUpError: stringifyAxiosError(error) }));
      throw error;
    }
    set(() => ({
      email: input.email,
      // temporarily persist password in state only to sign in user immediately after verification
      password: input.password,
      signUpError: '',
    }));
  },
  verifyCode: async (input) => {
    // Verify user
    try {
      await authService.verifyCode(input);
    } catch (error) {
      Sentry.captureException(error);

      set(() => ({ verifyCodeError: stringifyAxiosError(error) }));
      throw error;
    }

    // Then conduct initial login
    let auth: SignInOutput;
    try {
      auth = await authService.signIn({
        email: get().email,
        password: get().password,
      });
    } catch (error) {
      Sentry.captureException(error);
      set(() => ({ verifyCodeError: stringifyAxiosError(error) }));
      throw error;
    }
    const state = {
      id: auth.id,
      email: input.email,
      accessToken: auth.accessToken,
    };
    if (USE_LOCALSTORAGE_FOR_AUTH) {
      localStorage.setItem('customerAuthState', JSON.stringify(state));
    }
    set(() => ({
      ...state,
      // clear password from state
      verifyCodeError: '',
      password: '',
    }));
  },
  resendCode: async (input) => {
    await authService.resendVerificationCode(input);
  },
  initiatePasswordReset: async (input) => {
    try {
      await authService.initiatePasswordReset(input);
    } catch (error) {
      Sentry.captureException(error);
      set(() => ({ initiatePasswordResetError: stringifyAxiosError(error) }));
      throw error;
    }
    set(() => ({
      forgotPasswordEmail: input.email,
      initiatePasswordResetError: '',
    }));
  },
  confirmPasswordReset: async (input) => {
    try {
      await authService.confirmPasswordReset({
        email: get().forgotPasswordEmail,
        code: input.code,
        newPassword: input.newPassword,
      });
    } catch (error) {
      Sentry.captureException(error);
      set(() => ({ confirmPasswordResetError: stringifyAxiosError(error) }));
      throw error;
    }
    set(() => ({
      forgotPasswordEmail: '',
      confirmPasswordResetError: ',',
    }));
  },
  validateEmail: async (input) => {
    try {
      await authService.validateEmail(input);
    } catch (error) {
      Sentry.captureException(error);
      set(() => ({ validateEmailError: stringifyAxiosError(error) }));
      throw error;
    }
    set(() => ({ validateEmailError: '' }));
  },
}));
