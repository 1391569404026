declare global {
  interface String {
    pretty(): string;
  }
}

String.prototype.pretty = function () {
  const val = this;
  const result = val.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult.trim();
};

export default {};
