import React from 'react';

interface Props {
  total: number;
  current: number;
}

export const EllipseProgressTracker: React.FC<Props> = ({ total, current }) => (
  <div className="flex flex-row">
    {Array(total)
      .fill(null)
      .map((value, index) => (
        <div
          key={index}
          className={`rounded-full w-[12px] h-[12px] ${index > 0 && 'ml-2'} ${
            index <= current ? 'bg-blue' : 'bg-blue/50'
          }`}
        />
      ))}
  </div>
);
