import React, { useEffect, useMemo, useState } from 'react';
import { countryListStore } from '../store/countries-store';
import { SelectField } from './select-field';

interface Props {
  label: string
  country: string
  errorMessage?: string
  value: string
  onValueChange?: (value: string) => void
  [key: string]: unknown
}

export const RegionDropdown: React.FC<Props> = ({
  label, country, errorMessage, value, onValueChange, ...props
}) => {
  const [currentValue, setCurrentValue] = useState<string>('');

  const countries = countryListStore((state) => state.countries);
  const excludedStates = countryListStore((state) => state.excludedStates);
  const fetchCountries = countryListStore((state) => state.fetchCountries);
  const emptyState = { value: '', label: label || ' ' }

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const options = useMemo(() => countries
    .find((countryValue) => countryValue.countryName==country)?.regions
    .filter((region) => !excludedStates.includes(region.name))
    .map((region) => ({
      value: region.name,
      label: region.name,
    })), [country]);

  useEffect(() => {
    setCurrentValue('');
    if (onValueChange) {
      onValueChange('');
    }
  }, [country]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value, setCurrentValue]);

  return (
    <SelectField
      label={label}
      errorMessage={errorMessage}
      options={options ? [emptyState, ...options,]:[emptyState]}
      defaultValue=""
      value={currentValue}
      onValueChange={onValueChange}
      {...props}
    />
  );
};

RegionDropdown.defaultProps = {
  errorMessage: '',
  onValueChange: undefined,
};
