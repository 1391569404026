import React, { useCallback, useMemo, useState } from 'react';
import { InputField, InputFieldProps } from './input-field';

interface Props extends Omit<InputFieldProps, 'type' | 'icon'> {}

export const PasswordField: React.FC<Props> = React.forwardRef<any, Props>(({
  label, errorMessage, placeholder, showPlaceholder, ...props
}, ref) => {
  const [isPassword, setIsPassword] = useState(true);

  const toggleType = useCallback(() => {
    setIsPassword(!isPassword);
  }, [setIsPassword, isPassword]);

  const formType = useMemo(() => (isPassword ? 'password' : 'text'), [isPassword]);

  return (
    <InputField
      externalRef={ref}
      label={label}
      type={formType}
      errorMessage={errorMessage}
      placeholder={placeholder}
      showPlaceholder={showPlaceholder}
      {...props}
    >
      <button
        type="button"
        onClick={() => toggleType()}
        className="h-full flex flex-col justify-center"
        tabIndex={-1}
      >
        <span className="material-symbols-sharp text-red text-xl">
          {isPassword ? 'visibility_off' : 'visibility' }
        </span>
      </button>
    </InputField>
  );
});
