// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Button } from './button';
import { NavBarMobile } from './navbar-mobile';

interface Props {}

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
}

const initialNavBarStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  backgroundColor: '#FFFFFF',
  color: '#fff',
  padding: '10px 0',
};

const ulStyle = {
  listStyle: 'none',
  padding: '0',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
};

const liStyle = {
  margin: '0',
  padding: '5px',
  textAlign: 'center',
  fontWeight: '500',
};

const initialLogoLiStyle = {
  ...liStyle,
  marginRight: '15rem',
};

const rowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
};

const columnStyle = {
  flex: '1',
  padding: '5px',
  // textAlign: 'center',
  boxSizing: 'border-box',
  maxWidth: 'fit-content',
};

const logoColumn1Style = {
  ...columnStyle,
  paddingBottom: '.75rem'
};

const logoColumn3Style = {
  ...columnStyle,
  paddingBottom: '.8rem'
};

const buttonStyle = {
  background: 'linear-gradient(90deg, #7B3A4A 0%, #AB3D4F 51.89%, #FF9255 100%)',
  fontSize: '11px',
  fontWeight: '600',
};

const initialStyles = {
  navBarStyle: initialNavBarStyle,
  ulStyle,
  logoLiStyle: initialLogoLiStyle,
  rowStyle,
  columnStyle,
  logoColumn1Style,
  logoColumn3Style,
  buttonStyle,
};

export const NavBar: React.FC<Props> = () => {
  const [screenWidth, setScreenWidth] = useState('wide');
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [styles, setStyles] = useState(initialStyles);
  const [isMobileApp, setIsMobileApp] = useState(false);

  useEffect(() => {
    setIsMobileApp(window.isMobileApp === true);
  });

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    setScreenWidth((screenSize.width < 754) ? 'narrow' : (screenSize.width >= 754 && screenSize.width <= 990) ? 'mid' : 'wide');
    return (() => {
      window.removeEventListener('resize', updateDimension);
    });
  }, [screenSize]);

  useEffect(() => {
    if (screenWidth === 'narrow') {
      setStyles(
        { ...styles,
          logoLiStyle: {
            ...styles.logoLiStyle,
            marginRight: '0',
          }
        }
      );
    } else if (screenWidth === 'mid') {
      setStyles(
        { ...styles,
          logoLiStyle: {
            ...styles.logoLiStyle,
            marginRight: '0',
          }
        }
      );
    } else if (screenWidth === 'wide') {
      setStyles(
        { ...styles,
          navBarStyle: initialNavBarStyle,
          logoLiStyle: initialLogoLiStyle,
        }
      );
    }
  }, [screenWidth]);

  return (
    <>
      {(isMobileApp || screenWidth == 'narrow') && (
        <NavBarMobile />
      )}
      {(!isMobileApp && screenWidth != 'narrow') && (
        <nav style={styles.navBarStyle}>
          <ul style={ulStyle}>
            <li style={styles.logoLiStyle}>
              {/* <a href=""> */}
              <div style={rowStyle}>
                <div style={logoColumn1Style}>
                  <svg width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.9124 12.5525V11.7169C15.072 10.6242 11.7103 8.56722 7.24956 8.56725C2.78885 8.56727 -0.18499 13.3238 0.00895431 17.1805C0.202898 21.0373 1.17262 25.151 6.02122 30.1647C9.90009 34.1757 13.5204 36.6568 15.072 37.5567C15.2659 37.6424 15.7055 37.7624 15.9124 37.5567C16.1193 37.351 15.9986 37.1282 15.9124 37.0425C15.503 36.6354 13.5721 34.5871 9.12435 29.6505C4.67657 24.7139 3.90942 20.5658 4.08181 19.1089C3.90942 18.4661 4.00424 16.6277 5.76266 14.4165C7.52109 12.2054 12.8739 12.6382 15.3306 13.131C15.5245 13.1524 15.9124 13.0667 15.9124 12.5525Z" fill="#7B3A4A" />
                    <path d="M17.2566 12.5525V11.7169C18.0929 10.6242 21.4379 8.56722 25.8764 8.56725C30.3149 8.56727 33.2739 13.3238 33.081 17.1805C32.888 21.0373 31.9231 25.151 27.0986 30.1647C23.239 34.1757 19.6367 36.6568 18.0929 37.5567C17.8999 37.6424 17.4625 37.7624 17.2566 37.5567C17.0508 37.351 17.1709 37.1282 17.2566 37.0425C17.664 36.6354 19.5853 34.5871 24.0109 29.6505C28.4366 24.7139 29.1999 20.5658 29.0284 19.1089C29.1999 18.4661 29.1055 16.6277 27.3559 14.4165C25.6062 12.2054 20.28 12.6382 17.8356 13.131C17.6426 13.1524 17.2566 13.0667 17.2566 12.5525Z" fill="#7B3A4A" />
                    <path d="M11.2884 2.26926L12.6568 1.23329C13.0641 1.67728 14.0839 2.86124 14.9049 4.0452C15.726 5.22916 16.2896 7.72864 16.4688 8.83038C16.5828 7.66286 17.153 4.86411 18.5214 3.00924C19.8898 1.15436 21.7958 0.230215 22.5778 0C23.052 1.29196 23.052 1.90394 22.9199 3.00924C22.8313 3.75036 23.3597 4.09453 20.4274 7.00511C18.0816 9.33356 16.8109 10.0143 16.4688 10.0637H15.8335C15.4914 8.86327 14.6215 6.04807 13.8786 4.39052C13.1358 2.73298 11.8423 2.2857 11.2884 2.26926Z" fill="#679A41" />
                  </svg>
                </div>
                <div style={columnStyle}>
                  <h2 className="font-inter font-bold text-[2rem] text-red">
                    HeartMap
                  </h2>
                </div>
                <div style={logoColumn3Style}>
                  <h3 className="font-inter text-[1rem] text-black">
                    by Boston Heart Diagnostics
                  </h3>
                </div>
              </div>
              {/* </a> */}
            </li>
            <li style={liStyle} className="font-inter text-[0.8rem] text-black">
              <a href="https://bostonheartdiagnostics.com/heartmap" target="_blank" rel="noreferrer">
                HOW IT WORKS
              </a>
            </li>
            <li style={liStyle} className="font-inter text-[0.8rem] text-black">
              <a href="https://bostonheartdiagnostics.com/heartmap" target="_blank" rel="noreferrer">
                FAQS
              </a>
            </li>
            <li style={liStyle} className="font-inter text-[0.8rem] text-black">
              <a href="/product/standard">
                <Button
                  className="md:flex-none flex-1"
                  style={buttonStyle}
                >
                  SUBSCRIBE NOW
                </Button>
              </a>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};
