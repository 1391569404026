// @ts-nocheck
import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Button } from './button';

interface Props {}

const rowStyle = {
  display: 'flex',
  alignItems: 'end',
};

const columnStyle = {
  flex: '1',
  padding: '5px',
  // textAlign: 'center',
  boxSizing: 'border-box',
  maxWidth: 'fit-content',
};

const logoColumn1Style = {
  ...columnStyle,
  padding: '0',
  paddingBottom: '.75rem',
};

const buttonStyle = {
  background: 'linear-gradient(90deg, #7B3A4A 0%, #AB3D4F 51.89%, #FF9255 100%)',
  fontSize: '11px',
};

const drawerLiStyle = {
  justifyContent: 'center',
  padding: '25px 0',
  fontWeight: '500',
};

const horizLineStyle = {
  borderBottom: '.8px #CCCCCC solid',
  width: '90%',
  height: '1px',
};

export const NavBarMobile: React.FC<Props> = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <List style={{ position: 'relative', marginTop: '100px', justifyContent: 'center', }}>
          <ListItem style={drawerLiStyle}>
            <div className="font-inter text-[0.8rem] text-black">
              <a href="https://bostonheartdiagnostics.com/heartmap" target="_blank" rel="noreferrer">
                HOW IT WORKS
              </a>
            </div>
          </ListItem>
          <div style={{ display: 'flex', justifyContent: 'center', }}>
            <div style={horizLineStyle} />
          </div>
          <ListItem style={drawerLiStyle}>
            <div className="font-inter text-[0.8rem] text-black">
              <a href="https://bostonheartdiagnostics.com/heartmap" target="_blank" rel="noreferrer">
                FAQS
              </a>
            </div>
          </ListItem>
          <div style={{ display: 'flex', justifyContent: 'center', }}>
            <div style={horizLineStyle} />
          </div>
          <ListItem style={drawerLiStyle}>
            <div className="font-inter text-[0.8rem] text-black font-bold">
              <a href="/product/standard">
                <Button
                  className="md:flex-none flex-1"
                  style={buttonStyle}
                >
                  SUBSCRIBE NOW
                </Button>
              </a>
            </div>
          </ListItem>

        </List>
      </Drawer>
      <AppBar position="fixed" style={{ zIndex: '2000', }}>
        <Toolbar style={{ backgroundColor: '#FFFFFF', padding: '.7rem 0 .8rem 1.1rem', }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon style={{ color: '#2D2D2D', fontSize: '40px', }} />
          </IconButton>
          <div className="ml-4">
            <div style={rowStyle}>
              <div style={logoColumn1Style}>
                <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0911 7.96032V7.43042C9.55809 6.73748 7.42623 5.43302 4.59741 5.43303C1.76859 5.43305 -0.117314 8.44948 0.0056785 10.8953C0.128671 13.3411 0.743631 15.9499 3.81844 19.1294C6.27828 21.673 8.57415 23.2464 9.55809 23.8171C9.68108 23.8715 9.95986 23.9476 10.0911 23.8171C10.2223 23.6867 10.1457 23.5454 10.0911 23.491C9.83141 23.2328 8.60695 21.9339 5.78633 18.8033C2.96571 15.6727 2.47921 13.0421 2.58854 12.1181C2.47921 11.7105 2.53934 10.5447 3.65447 9.14245C4.7696 7.74021 8.16418 8.01468 9.72208 8.32719C9.84507 8.34078 10.0911 8.28643 10.0911 7.96032Z" fill="#7B3A4A" />
                  <path d="M10.9435 7.96032V7.43042C11.4738 6.73748 13.5951 5.43302 16.4099 5.43303C19.2246 5.43305 21.1011 8.44948 20.9787 10.8953C20.8564 13.3411 20.2445 15.9499 17.1849 19.1294C14.7373 21.673 12.4529 23.2464 11.4738 23.8171C11.3515 23.8715 11.0741 23.9476 10.9435 23.8171C10.813 23.6867 10.8891 23.5454 10.9435 23.491C11.2019 23.2328 12.4203 21.9339 15.2268 18.8033C18.0334 15.6727 18.5175 13.0421 18.4087 12.1181C18.5175 11.7105 18.4577 10.5447 17.3481 9.14245C16.2385 7.74021 12.8608 8.01468 11.3107 8.32719C11.1883 8.34078 10.9435 8.28643 10.9435 7.96032Z" fill="#7B3A4A" />
                  <path d="M7.1587 1.43908L8.02649 0.78211C8.28476 1.06367 8.93148 1.81449 9.45215 2.56532C9.97283 3.31615 10.3303 4.90122 10.4439 5.59991C10.5162 4.85951 10.8778 3.08464 11.7456 1.90835C12.6134 0.732055 13.8221 0.145994 14.318 0C14.6188 0.819313 14.6188 1.20741 14.535 1.90835C14.4788 2.37834 14.8139 2.5966 12.9543 4.44238C11.4667 5.91901 10.6609 6.35073 10.4439 6.38202H10.041C9.82407 5.62076 9.2724 3.83547 8.80131 2.78431C8.33022 1.73316 7.50995 1.44951 7.1587 1.43908Z" fill="#679A41" />
                </svg>
              </div>
              <div style={columnStyle}>
                <h2 className="font-inter font-bold text-[1.4rem] text-red">
                  HeartMap
                </h2>
              </div>
            </div>
            <div style={{ ...rowStyle, marginTop: '-.7rem' }}>
              <h3 className="font-inter text-[.7rem] text-black">
                by Boston Heart Diagnostics
              </h3>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};
