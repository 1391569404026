import { create } from 'zustand';
import { GiftsService } from '../services/gifting/service';
import * as Sentry from "@sentry/browser";

import type {
  Gift,
  RedeemGiftCodeInput,
  RedeemGiftCodeOutput
} from '../services/gifting/types';
import { stringifyAxiosError } from '../utils/axios-utils';
import { useAuthStore } from './auth-store';
import { errorHandler } from '../utils/errorHandler';

const giftsService = new GiftsService();

interface GiftingState extends RedeemGiftCodeOutput {
  validGiftCode: string
  redeemGiftCodeError: string
  redeemGiftCode: {(input: RedeemGiftCodeInput): Promise<void>}
  getGiftsOfUser: {(): Promise<void>}
  getGiftsOfUserError: string
  giftsOfUser: Gift[]
}

export const useGiftingStore = create<GiftingState>((set) => ({
  validGiftCode: '',
  redeemGiftCodeError: '',
  giftsOfUser: [],
  getGiftsOfUserError: '',
  redeemGiftCode: async (input: RedeemGiftCodeInput) => {
    let isCodeValid = false;
    try {
      const { valid } = await giftsService.validateGiftCode(input);
      isCodeValid = valid;
      if (!isCodeValid) {
        set(() => ({ redeemGiftCodeError: 'Invalid gift code!' }));
      }
    } catch (error) {
      Sentry.captureException(error);
      set(() => ({ redeemGiftCodeError: stringifyAxiosError(error) }));
      throw error;
    }
    const state = {
      validGiftCode: isCodeValid ? input.giftCode : '',
      redeemGiftCodeError: isCodeValid ? '' : 'Invalid gift code!'
    };
    set(() => ({
      ...state
    }));
  },
  getGiftsOfUser: async () => {
    const { accessToken, email } = useAuthStore.getState();
    let gifts = [];
    // if (accessToken) {
    //   return;
    // }
    try {
      gifts = await giftsService.getGifts({
        email, accessToken
      });
      if (gifts) {
        const state = {
          giftsOfUser: gifts,
        };
        set(() => ({
          ...state
        }));
      }
    } catch (err) {
      const error = err as Error & { status?: string };
      const errMessage = errorHandler(error);
      console.error(errMessage);
      Sentry.captureException(error);
      set(() => ({ getGiftsOfUserError: errMessage }));
      throw error;
    }
  }
}));
