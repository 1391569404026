import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CountryName } from 'country-region-data';
import { Seo } from '../components/seo';
import { MultipartSignUpForm } from '../components/multipart-sign-up-form';
import { useMobile } from '../hooks/useMobile';
import { useGiftingStore } from '../store/gifting-store';
import * as Sentry from "@sentry/browser";


function SignUpPage() {
  const defaultCountry: CountryName = import.meta.env.VITE_COUNTRY;

  const isFromMobile = useMobile();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const giftCode = searchParams.get('giftCode') || '';
  const { validGiftCode, redeemGiftCode, redeemGiftCodeError } =
    useGiftingStore();

  useEffect(() => {
    // Call the redeemGiftCode function with the giftCode from the URL
    async function fetchData() {
      try {
        await redeemGiftCode({ giftCode });
      } catch (error) {
        Sentry.captureException(error);

        console.error('Error redeeming gift code:', error);
      }
    }

    if (giftCode) {
      fetchData();
    }
  }, [giftCode]);

  useEffect(() => {
    if (validGiftCode) {
      localStorage.removeItem('customerAuthState');
    }
  }, [validGiftCode]);

  return (
    <>
      <Seo title="Sign Up" />
      <h2 className="font-inter font-bold text-[2rem] text-black">Sign Up</h2>
      {validGiftCode && !redeemGiftCodeError && (
        <h2 className="text-green">
          You are using gift code "
          {giftCode}
          "
        </h2>
      )}
      {redeemGiftCodeError && (
        <h2 className="text-error">
          Invalid gift code "
          {giftCode}
          "
        </h2>
      )}
      <div className="mt-9 max-w-[672px]">
        <div className="mt-[2.875rem]">
          {defaultCountry ? (
            <MultipartSignUpForm
              defaultCountry={defaultCountry}
              giftCode={giftCode}
            />
          ) : (
            <MultipartSignUpForm giftCode={giftCode} />
          )}
        </div>
      </div>
      {!validGiftCode && !isFromMobile && (
        <>
          <div className="relative w-full flex flex-row mt-10">
            <div className="flex-1 flex flex-col justify-center">
              <div className="border-t border-gray w-full" />
            </div>
            <div className="px-6 text-gray-medium text-xs">or</div>
            <div className="flex-1 flex flex-col justify-center">
              <div className="border-t border-gray w-full" />
            </div>
          </div>
          <div className="md:mt-10 mt-6 text-center">
            <span className="text-gray-medium font-medium">
              Have an account already?
            </span>
            <span className="pl-2">
              <Link to="/login" className="text-blue font-medium">
                Log In
              </Link>
            </span>
          </div>
        </>
      )}
    </>
  );
}

export default SignUpPage;
