import type { AxiosError } from 'axios';
import create from 'zustand';
import validator from 'validator';
import { CheckoutService } from '../services/checkout/service';
import { useAuthStore } from './auth-store';
import * as Sentry from "@sentry/browser";


interface CheckoutState {
  slug: string
  webUrl: string
  error: AxiosError | unknown
  checkout: {(): Promise<void>}
  setSlug: {(slug: string | undefined): void}
}

const checkoutService = new CheckoutService();

const initialSlug = localStorage.getItem('product-slug') || '';

export const useCheckoutStore = create<CheckoutState>((set, get) => ({
  slug: initialSlug,
  webUrl: '',
  error: null,
  checkout: async () => {
    const { accessToken, email } = useAuthStore.getState();
    if (!accessToken) {
      return;
    }
    const valid = validator.isEmail(email);
    if (!valid) {
      return;
    }
    let webUrl = '';
    console.log({ slug: get().slug });
    if(get().slug === ''){
      return
    }
    try {
      const result = await checkoutService.checkout({
        accessToken,
        email,
        slug: get().slug,
      });
      webUrl = result.webUrl || '';
    } catch (error) {
      Sentry.captureException(error);
      console.log(error)
      set(() => ({ error }));
      throw error;
    }
    set(() => ({ webUrl }));
  },
  setSlug: (slug) => {
    set(() => ({ slug }));
    localStorage.setItem('product-slug', slug as string || '');
  },
}));
