import { Seo } from '../components/seo';

function Error404Page() {
  return (
    <>
      <Seo title="404" description="Not found" />
      <h1 className="text-center text-5xl font-bold">404 not found.</h1>
    </>
  );
}

export default Error404Page;
