import axios from 'axios';
import type { AxiosInstance } from 'axios';

export class EventsService {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: import.meta.env.VITE_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async getEventBySlug(input: string) {
    const response = await this.instance.get(`/events/${input}`);
    return response.data;
  }
}
