import React, { useCallback, useState } from 'react';
import { useAuthStore } from '../store/auth-store';
import * as Sentry from "@sentry/browser";


export const ResendCodeButton: React.FC = () => {
  const email = useAuthStore((state) => state.email);
  const resendCode = useAuthStore((state) => state.resendCode);
  const [isCodeResent, setIsCodeResent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const resend = useCallback(async () => {
    setIsCodeResent(false);
    setIsLoading(true);
    try {
      await resendCode({ email });
    } catch (error) {
      Sentry.captureException(error);
      setIsLoading(false);
      throw error;
    }
    setIsLoading(false);
    setIsCodeResent(true);
  }, [email, resendCode, setIsCodeResent, setIsLoading]);

  return (
    <>
      <p>
        <span>Didn&apos;t receive code? </span>
        <button
          type="button"
          className="text-blue"
          onClick={() => resend()}
        >
          Resend Code
        </button>
      </p>
      <div className="mt-2">
        {isCodeResent && <p>Code resent.</p>}
        {isLoading && <p>Sending...</p>}
        {/* Apply final spinner when available */}
      </div>
    </>
  );
};
