import React, { useEffect, useMemo } from 'react';
import { countryListStore } from '../store/countries-store';
import { SelectField } from './select-field';

interface Props {
  label: string
  errorMessage?: string
  value: string
  onValueChange?: (value: string) => void
  [key: string]: unknown
}

export const CountryDropdown: React.FC<Props> = ({
  label, errorMessage, value, onValueChange, ...props
}) => {
  const countries = countryListStore((state) => state.countries);
  const fetchCountries = countryListStore((state) => state.fetchCountries);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const options = useMemo(() => countries.map((country) => ({
    value: country.countryName,
    label: country.countryName,
  })), []);

  return (
    <SelectField
      label={label}
      errorMessage={errorMessage}
      options={[
        { value: '', label: ' ' },
        ...options,
      ]}
      defaultValue=""
      value={value}
      onValueChange={onValueChange}
      {...props}
    />
  );
};

CountryDropdown.defaultProps = {
  errorMessage: '',
  onValueChange: undefined,
};
