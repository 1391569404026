import { CountryName } from 'country-region-data';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Seo } from '../components/seo';
import { OnsiteMultipartSignUpForm } from '../components/onsite-multipart-sign-up-form';
import { useSubdomainStore } from '../store/subdomain-store';
import { useNavigate } from 'react-router-dom';

function RegistrationPage() {
  const { slug } = useParams();
  const { setEvent, event } = useSubdomainStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      setEvent(slug).catch((e) => {
        navigate('/404', { replace: true });
      });
    }
  }, [slug]);

  const defaultCountry: CountryName = import.meta.env.VITE_COUNTRY;
  return (
    <>
      <Seo title="Sign Up" />
      <h2 className="font-inter font-bold text-[2rem] text-black">Sign Up</h2>
      <small>{event?.name || ''}</small>
      <div className="pt-[10px]">
        By registering and providing your personal details, we will be able to
        perform your test and provide results securely through our mobile app.
      </div>
      <div className="mt-9 max-w-[672px]">
        <div className="mt-[2.875rem]">
          {defaultCountry ? (
            <OnsiteMultipartSignUpForm
              defaultCountry={defaultCountry}
              event={event}
            />
          ) : (
            <OnsiteMultipartSignUpForm event={event} />
          )}
        </div>
      </div>
    </>
  );
}

export default RegistrationPage;
