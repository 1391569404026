import { Link } from 'react-router-dom';
import { Seo } from '../components/seo';
import { LoginForm } from '../components/login-form';
import { useMobile } from '../hooks/useMobile';

function LoginPage() {
  const isFromMobile = useMobile();

  const { VITE_APP_NAME } = import.meta.env;

  return (
    <>
      <Seo title="Login" />
      <h4 className="text-black font-inter text-xl leading-6">Log in to your</h4>
      <h2 className="text-black font-inter text-[2rem] leading-[2.4rem] font-semibold mt-2">
        {VITE_APP_NAME}
        {' '}
        account
      </h2>
      <div className="mt-[1.125rem]">
        <LoginForm />
      </div>
      {!isFromMobile && (
        <>
          <div className="relative w-full flex flex-row mt-10">
            <div className="flex-1 flex flex-col justify-center">
              <div className="border-t border-gray w-full" />
            </div>
            <div className="px-6 text-gray-medium text-xs">or</div>
            <div className="flex-1 flex flex-col justify-center">
              <div className="border-t border-gray w-full" />
            </div>
          </div>
          <div className="md:mt-12 mt-6 md:text-left text-center">
            <span className="text-gray-medium font-medium">Don&#39;t have an account?</span>
            <span className="pl-2">
              <Link to="/sign-up" className="text-blue font-medium">Sign Up</Link>
            </span>
          </div>
        </>
      )}
    </>
  );
}

export default LoginPage;
