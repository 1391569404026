import validator from 'validator';

export const validatePassword = (password: string): boolean => {
  const valid = validator.isStrongPassword(password, {
    minLength: 8,
    minNumbers: 1,
    minSymbols: 1,
    minUppercase: 1,
    minLowercase: 1,
  });
  return valid;
};
