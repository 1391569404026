import axios from 'axios';
import type { AxiosInstance } from 'axios';

export class CountryListService {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: import.meta.env.VITE_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  async getCountries() {
    const response = await this.instance.get(`/countries`);
    return response.data;
  }

}
