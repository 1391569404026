import { useEffect, useState } from 'react';
import { Seo } from '../components/seo';
import { useSubdomainStore } from '../store/subdomain-store';

function SuccessRegistration() {
  const { VITE_APPLE_STORE_LINK, VITE_GOOGLE_PLAY_LINK } = import.meta.env;
  const { isOnsite } = useSubdomainStore();
  const [isMobileApp, setIsMobileApp] = useState(false);

  useEffect(() => {
    // @ts-ignore
    setIsMobileApp(window.isMobileApp === true);
  });

  return (
    <>
      <Seo title="Successful Registration" />
      <h2 className="font-inter font-bold text-[2rem] text-black">
        Successful Registration
      </h2>
      <div className="mt-2 max-w-[672px]">
        {isOnsite
          ? `Once your test has been completed, you will be notified by email that your results are available within our app. ${
              !isMobileApp
                ? 'You can download the app using the links below. Your log-in credentials will be the email and password you provided during registration.'
                : ''
            }`
          : `You have successfully created an account!`}
      </div>
      {!isMobileApp && (
        <div className="mt-[80px] min-h-[65px]">
          Download the HeartMap app now!
          <div className="flex flex-row justify mt-5">
            <div className="w-full md:w-1/2">
              <a href={VITE_APPLE_STORE_LINK} target="_blank">
                <img
                  src="/app-store-badge.png"
                  alt="Image 1"
                  className="w-[180px] h-[50px] md:h-[60px] md:w-[220px] pr-1"
                ></img>
              </a>
            </div>
            <div className="w-full md:w-1/2">
              <a href={VITE_GOOGLE_PLAY_LINK} target="_blank">
                <img
                  src="/google-play-badge.png"
                  alt="Image 2"
                  className="w-[180px] h-[50px] md:h-[60px] md:w-[220px] pr-1"
                ></img>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SuccessRegistration;
