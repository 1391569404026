import React, { useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Link, useNavigate,
} from 'react-router-dom';
import { SignInInput } from '../services/auth/types';
import { useAuthStore } from '../store/auth-store';
import { Button } from './button';
import { ErrorMessage } from './error-message';
import { InputField } from './input-field';
import { PasswordField } from './password-field';
import { errorHandler } from '../utils/errorHandler';
import * as Sentry from "@sentry/browser";


export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const signIn = useAuthStore((state) => state.signIn);
  const signInError = useAuthStore((state) => state.signInError);
  const [success, setSuccess] = useState<boolean>(false);
  const {
    register, handleSubmit, formState: { errors, isSubmitting },
  } = useForm<SignInInput>();
  const onSubmit = async (data: SignInInput) => {
    try {
      await signIn({
        email: data.email,
        password: data.password,
      });
      setSuccess(true);
    } catch (err) {
      const error = err as Error & { status?: string };
      const errMessage = errorHandler(error);
      console.log(errMessage)
      Sentry.captureException(err)
      if (errMessage === 'User is not confirmed.') {
        navigate('/verify-code');
      }
    }
  };
  const requiredFieldErrorMessage = 'This field is required';
  const loading = useMemo(
    () => isSubmitting || success,
    [isSubmitting, success],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {signInError && <ErrorMessage>{signInError}</ErrorMessage>}
      <fieldset>
        <InputField
          label="Email Address"
          type="email"
          errorMessage={errors.email?.message}
          {...register('email', {
            required: requiredFieldErrorMessage,
          })}
        />
      </fieldset>
      <fieldset className="mt-3">
        <PasswordField
          label="Password"
          errorMessage={errors.password?.message}
          {...register('password', {
            required: requiredFieldErrorMessage,
          })}
        />
      </fieldset>
      <div className="mt-2">
        <Link className="text-red font-medium font-inter" to="/forgot-password">Forgot Password?</Link>
      </div>
      <fieldset className="flex flex-row justify-end md:mt-2 mt-6">
        <Button
          className="md:flex-none flex-1"
          type="submit"
          loading={loading}
        >
          {loading ? 'Loading' : 'Login'}
        </Button>
      </fieldset>
    </form>
  );
};
