export interface SignInInput {
  email: string;
  password: string;
}

export interface SignInResponse {
  idToken: {
    jwtToken: string;
    payload: {
      [key: string]: unknown;
    };
  };
  refreshToken: {
    token: string;
  };
  accessToken: {
    jwtToken: string;
    payload: {
      sub: string;
      [key: string]: unknown;
    };
  };
  clockDrift: number;
}

export interface SignInOutput {
  id: string;
  accessToken: string;
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export interface AccountDetailsInput {
  email: string;
  password: string;
}

export interface PersonalInformationInput {
  firstName: string;
  lastName: string;
  middleName?: string;
  birthdate: Date;
  gender: string;
}

export interface OnsitePersonalInformationInput {
  firstName: string;
  lastName: string;
  middleName?: string;
  birthdate: Date;
  gender: string;
  phoneNumber: string;
  registeredAt: string;
  termsAndConditions: boolean;
  shareWithPhysicianNetwork: boolean;
  isNotIntendedToDiagnose: boolean;
  event?: string;
}

export interface AddressInformationInput {
  phoneNumber: string;
  address1: string;
  address2: string;
  country: string;
  city: string;
  state: string;
  zip: string;
  termsAndConditions: boolean;
  shareWithPhysicianNetwork: boolean;
  isNotIntendedToDiagnose: boolean;
}

export interface SignUpInput
  extends AccountDetailsInput,
    PersonalInformationInput,
    AddressInformationInput {
  giftCode?: string;
}

export interface OnsiteSignUpInput
  extends AccountDetailsInput,
    OnsitePersonalInformationInput {}

export interface CodeVerificationInput {
  email: string;
  code: string;
}

export interface ResendCodeInput {
  email: string;
}

export interface InitiateResetPasswordInput {
  email: string;
}

export interface InitiateResetPasswordResponse {
  status: string;
  msg: string;
}

export interface ConfirmResetPasswordInput extends InitiateResetPasswordInput {
  code: string;
  newPassword: string;
}

export interface ValidateEmailInput {
  email: string;
}
