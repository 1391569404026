import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import lookup from 'country-code-lookup';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';
import { Country } from 'react-phone-number-input';
import { CSSTransition } from 'react-transition-group';
import { DateTime } from 'luxon';
import PhoneInput from 'react-phone-input-2';
import * as Sentry from '@sentry/browser';
import {
  Gender,
  AccountDetailsInput,
  OnsitePersonalInformationInput,
  OnsiteSignUpInput,
} from '../services/auth/types';
import { SelectField } from './select-field';
import { Checkbox } from './checkbox';
import { useAuthStore } from '../store/auth-store';
import { Button } from './button';
import { ErrorMessage } from './error-message';
import { InputField } from './input-field';
import { PasswordField } from './password-field';
import { EllipseProgressTracker } from './ellipse-progress-tracker';
import { validatePassword } from '../utils/validators';
import { countryListStore } from '../store/countries-store';
import DatePicker from './DatePicker';
import TermsAndConditionModal from './TermsAndConditionModal';
import { useRegistrationStore } from '../store/registration-store';
import 'react-phone-input-2/lib/style.css';

interface FormPartProps<T> {
  onSubmit: { (data: T): Promise<void> };
  onBack: { (): void };
  mainFormValues: T;
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  defaultCountry?: string;
  event?: any;
}

interface AccountDetailsLocalInput extends AccountDetailsInput {
  confirmPassword: string;
}

const requiredErrorMessage = 'This field is required.';

const AccountDetailsForm: React.FC<FormPartProps<AccountDetailsInput>> = ({
  onSubmit,
  onBack,
  mainFormValues,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<AccountDetailsLocalInput>({
    defaultValues: {
      email: mainFormValues.email,
      password: mainFormValues.password,
      confirmPassword: mainFormValues.password,
    },
  });
  const { validateEmailError, validateEmail } = useAuthStore();

  const submit = async (data: AccountDetailsLocalInput) => {
    try {
      await validateEmail({ email: data.email });
      await onSubmit(data);
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
    }
  };

  const password = watch('password');

  // Did not use form element here to avoid premature
  // prompting of password saving, which is the default
  // behaviour of most browsers.
  return (
    <form onSubmit={handleSubmit(submit)}>
      <fieldset className="mt-5">
        {validateEmailError && (
          <ErrorMessage>{validateEmailError}</ErrorMessage>
        )}
        <div>
          <InputField
            label="Email"
            type="email"
            errorMessage={errors.email?.message}
            {...register('email', {
              required: requiredErrorMessage,
            })}
          />
        </div>
        <div className="mt-5">
          <PasswordField
            label="Password"
            errorMessage={errors.password?.message}
            {...register('password', {
              required: requiredErrorMessage,
              validate: (value) =>
                validatePassword(value) ||
                `Your password must contain:
  - At least 8 characters
  - At least one lowercase letter (a-z)
  - At least one uppercase letter (A-Z)
  - At least one number (0-9)  
  - At least one special character`,
            })}
          />
        </div>
        <div className="mt-5">
          <PasswordField
            label="Confirm Password"
            errorMessage={errors.confirmPassword?.message}
            {...register('confirmPassword', {
              required: requiredErrorMessage,
              validate: (value) =>
                value === password || 'Password must be the same.',
            })}
          />
        </div>
      </fieldset>
      <fieldset className="mt-11">
        <div className="border-t border-gray w-full md:block hidden" />
        <div className="flex flex-row justify-end">
          <div className="flex-1 pr-2">
            <Button
              colorClassname="md:bg-gray md:font-normal font-semibold text-black hidden block"
              spacingClassName="md:px-6 py-2"
              type="submit"
              onClick={() => onBack()}
            >
              Back
            </Button>
          </div>
          <Button
            className="md:flex-[5] flex-[2]"
            type="submit"
            loading={isSubmitting}
          >
            <span className="md:block hidden">Next: Personal Information</span>
            <span className="md:hidden block">Next</span>
          </Button>
        </div>
      </fieldset>
    </form>
  );
};

const OnsitePersonalInformationForm: React.FC<
  FormPartProps<OnsitePersonalInformationInput>
> = ({ onSubmit, onBack, mainFormValues, children, defaultCountry }) => {
  const { signUpError } = useAuthStore();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<OnsitePersonalInformationInput>({
    defaultValues: mainFormValues,
  });

  const countryCode = useMemo<Country>(() => {
    const code = lookup.byCountry(defaultCountry as string)
      ?.internet as Country;
    return code;
  }, [defaultCountry]);

  const submit = async (data: OnsitePersonalInformationInput) => {
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      {children && children}
      <fieldset>
        {signUpError && <ErrorMessage>{signUpError}</ErrorMessage>}
        <div className="mt-5">
          <InputField
            label="First Name"
            type="text"
            errorMessage={errors.firstName?.message}
            {...register('firstName', {
              required: requiredErrorMessage,
            })}
          />
        </div>
        <div className="mt-5">
          <InputField
            label="Middle Name (Optional)"
            type="text"
            errorMessage={errors.middleName?.message}
            {...register('middleName')}
          />
        </div>
        <div className="mt-5">
          <InputField
            label="Last Name"
            type="text"
            errorMessage={errors.lastName?.message}
            {...register('lastName', {
              required: requiredErrorMessage,
            })}
          />
        </div>

        <div className="mt-5">
          <Controller
            control={control}
            name="birthdate"
            rules={{
              required: requiredErrorMessage,
              validate(value) {
                if (value) {
                  const diff = DateTime.now()
                    .diff(DateTime.fromJSDate(value), ['years'])
                    .toObject();
                  const yearsDiff = Math.floor(diff.years || 0);
                  if (yearsDiff < 18) {
                    return 'Laboratory testing for HeartMap is only available for adults (18 and older).';
                  }
                }
                return undefined;
              },
            }}
            render={({ field }) => (
              <DatePicker
                label="Birthdate"
                value={field.value}
                onChange={field.onChange}
                errorMessage={errors.birthdate?.message}
              />
            )}
          />
        </div>
        <div className="mt-5">
          <label className="">
            <small>Gender</small>
          </label>
          <Controller
            control={control}
            name="gender"
            rules={{
              required: requiredErrorMessage,
            }}
            render={({ field }) => (
              <SelectField
                label=""
                errorMessage={errors.gender?.message}
                options={[
                  { value: Gender.MALE, label: 'Male' },
                  { value: Gender.FEMALE, label: 'Female' },
                ]}
                defaultValue=""
                value={field.value}
                onValueChange={(value) => field.onChange(value)}
              />
            )}
          />
        </div>
        <div className="mt-5">
          <label className="">
            <small>Mobile Number</small>
          </label>
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              required: requiredErrorMessage,
              validate: (value) =>
                validator.isMobilePhone(value || '') ||
                `Phone number must be valid.${value}`,
            }}
            render={({ field }) => (
              <PhoneInput
                country="us"
                value={field.value}
                copyNumbersOnly={false}
                onChange={(value) => field.onChange(`+${value}`)}
              />
            )}
          />
          <div className="text-error text-xs">
            {errors.phoneNumber && <span>{errors.phoneNumber.message}</span>}
          </div>
        </div>
      </fieldset>
      <fieldset className="mt-11 gap-2 flex flex-col">
        <div>
          <Checkbox
            label={<TermsAndConditionModal />}
            errorMessage={errors.termsAndConditions?.message}
            {...register('termsAndConditions', {
              validate: (value) =>
                value ? true : 'You must acknowledge this term.',
            })}
          />
        </div>
        <div>
          <Checkbox
            label="I acknowledge that my personal information will be used by the laboratory according to HIPAA regulations for the performance of my lab tests and that de-identified data will be used to improve algorithms."
            errorMessage={errors.shareWithPhysicianNetwork?.message}
            {...register('shareWithPhysicianNetwork', {
              validate: (value) =>
                value ? true : 'You must acknowledge this term.',
            })}
          />
        </div>
        <div>
          <Checkbox
            label="I acknowledge that my test results are not intended to diagnose or treat any condition, illness or disease."
            errorMessage={errors.isNotIntendedToDiagnose?.message}
            {...register('isNotIntendedToDiagnose', {
              validate: (value) =>
                value ? true : 'You must acknowledge this term.',
            })}
          />
        </div>
      </fieldset>
      <fieldset className="mt-11">
        <div className="border-t border-gray w-full md:block hidden" />
        <div className="flex flex-row justify-end">
          <div className="flex-1 pr-2">
            <Button
              colorClassname="md:bg-gray md:font-normal font-semibold text-black"
              spacingClassName="md:px-6 py-2"
              onClick={() => onBack()}
            >
              Back
            </Button>
          </div>
          <Button
            className="md:flex-[5] flex-[2]"
            type="submit"
            loading={isSubmitting}
          >
            Create an Account
          </Button>
        </div>
      </fieldset>
    </form>
  );
};

interface Props {
  defaultCountry?: string;
  event?: any;
}

enum FormPage {
  ACCOUNT = 0,
  PERSONAL = 1,
}

export const OnsiteMultipartSignUpForm: React.FC<Props> = ({
  defaultCountry,
  event,
}: Props) => {
  const navigate = useNavigate();
  const { onsiteSignUp } = useAuthStore();
  const { watch, handleSubmit, setValue } = useForm<OnsiteSignUpInput>({});
  const [formPage, setFormPage] = useState<FormPage>(FormPage.ACCOUNT);

  const formData = watch();

  const fetchCountries = countryListStore((state) => state.fetchCountries);
  const excludedStates = countryListStore((state) => state.excludedStates);
  const { setHasRegistered } = useRegistrationStore();

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const excludedStatesDisclaimer = useCallback(() => {
    const excludedStatesCount = excludedStates.length;
    if (excludedStatesCount <= 1) {
      return excludedStates.join('');
    }
    return `${excludedStates.slice(0, -1).join(', ')}${
      excludedStatesCount > 2 ? ',' : ''
    } and ${excludedStates.slice(-1)}`;
  }, [excludedStates]);

  const submit = handleSubmit(async (data: OnsiteSignUpInput) => {
    await onsiteSignUp(data);
    setHasRegistered(true);
    navigate('/registration-success');
  });

  const formName = useMemo(() => {
    switch (formPage) {
      case FormPage.ACCOUNT:
        return 'Account Details';
      case FormPage.PERSONAL:
        return 'Personal Information';
      default:
        return '';
    }
  }, [formPage]);

  const transitionRef = useRef(null);

  return (
    <div className="relative">
      <div className="flex flex-row justify-between">
        <h4 className="font-semibold font-inter text-xl">{formName}</h4>
        <div className="flex flex-col justify-center">
          <EllipseProgressTracker total={2} current={formPage} />
        </div>
      </div>
      {formPage === FormPage.ACCOUNT && (
        <AccountDetailsForm
          mainFormValues={formData}
          onBack={() => {
            navigate('/registration');
          }}
          onSubmit={async (data) => {
            setValue('email', data.email);
            setValue('password', data.password);
            setFormPage(FormPage.PERSONAL);
          }}
        />
      )}
      {formPage === FormPage.PERSONAL && (
        <OnsitePersonalInformationForm
          mainFormValues={formData}
          onBack={() => setFormPage(FormPage.ACCOUNT)}
          defaultCountry={defaultCountry}
          onSubmit={async (data) => {
            setValue('phoneNumber', data.phoneNumber);
            setValue('firstName', data.firstName);
            setValue('lastName', data.lastName);
            setValue('middleName', data.middleName);
            setValue('birthdate', data.birthdate);
            setValue('gender', data.gender);
            setValue('registeredAt', 'on-site');
            setValue('event', event);
            setValue(
              'shareWithPhysicianNetwork',
              data.shareWithPhysicianNetwork
            );
            setValue('isNotIntendedToDiagnose', data.isNotIntendedToDiagnose);
            await submit();
          }}
        >
          {/**
           * Note: These hidden inputs are here in order to trigger
           * the default behaviour browsers do, which prompts the user
           * if they want to save the password.
           */}
          <input type="hidden" name="email" value={formData.email} />
          <input type="hidden" name="password" value={formData.password} />
        </OnsitePersonalInformationForm>
      )}
      {excludedStates.length > 0 && (
        <CSSTransition
          nodeRef={transitionRef}
          unmountOnExit
          in={formPage === FormPage.PERSONAL}
          timeout={300}
          classNames="fade-down"
        >
          <div className="flex flex-row mt-[20px] gap-2" ref={transitionRef}>
            <span
              className="material-symbols-outlined text-blue"
              style={{ fontSize: 20 }}
            >
              info
            </span>
            <span className="text-base text-black">
              Laboratory testing for HeartMap is not currently available in{' '}
              {excludedStatesDisclaimer()}.
            </span>
          </div>
        </CSSTransition>
      )}
    </div>
  );
};

OnsiteMultipartSignUpForm.defaultProps = {
  defaultCountry: 'United States',
  event: undefined,
};
