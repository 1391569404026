import { Seo } from '../components/seo';

function Error401Page() {
  return (
    <>
      <Seo title="401" description="Unauthorized" />
      <h1 className="text-center text-5xl font-bold">401 Unauthorized.</h1>
    </>
  );
}

export default Error401Page;
