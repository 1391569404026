import React from 'react';

interface Props {
  children: React.ReactNode
}

export const ErrorMessage: React.FC<Props> = ({ children }) => (
  <div className="mb-4 border border-red bg-red/25">
    <p className="text-red text-center">{children}</p>
  </div>
);
