import { useEffect, useState } from 'react';
import { Seo } from '../components/seo';
import { NavBar } from '../components/navbar';

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
}

const pageBodyStyle = {
  backgroundColor: '#F8F8F8',
  marginTop: '0',
  padding: '90px 0',
  backgroundImage: 'url( \'/confetti.png\' )',
  // backgroundRepeat: 'repeat',
  // backgroundPosition: '25% 75%',
};

const rowStyle = {
  display: 'flex',
  flex: '1',
  justifyContent: 'center',
  width: '100%',
};

const boxStyle = {
  backgroundColor: '#FFF',
  padding: '60px',
  margin: '50px',
  width: '47%',
};

const appStoreImageStyle = {
  height: '60px',
  width: '200px',
  marginTop: '0',
};

const appStoreContainerStyle = {};

const initialStyles = {
  pageBodyStyle,
  rowStyle,
  boxStyle,
  appStoreImageStyle,
  appStoreContainerStyle,
};

function SuccessRegistrationGift() {
  const { VITE_APPLE_STORE_LINK, VITE_GOOGLE_PLAY_LINK } = import.meta.env;
  const [isMobileApp, setIsMobileApp] = useState(false);
  const [screenWidth, setScreenWidth] = useState('wide');
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [styles, setStyles] = useState(initialStyles);

  useEffect(() => {
    // @ts-ignore
    setIsMobileApp(window.isMobileApp === true);
  });

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    setScreenWidth((screenSize.width < 768 || isMobileApp) ? 'narrow' : (screenSize.width >= 768 && screenSize.width <= 1029) ? 'mid' : 'wide');
    return (() => {
      window.removeEventListener('resize', updateDimension);
    });
  }, [screenSize]);

  useEffect(() => {
    if (screenWidth === 'narrow') {
      setStyles(
        { ...styles,
          pageBodyStyle: {
            ...styles.pageBodyStyle,
            padding: '50px 0',
          },
          boxStyle: {
            ...styles.boxStyle,
            width: '110%',
            padding: '30px',
            margin: '50px 30px',
          },
          appStoreImageStyle: {
            ...styles.appStoreImageStyle,
            height: '60px',
            width: '200px',
            marginTop: '15px',
          },
          appStoreContainerStyle: {
            ...styles.appStoreContainerStyle,
            display: 'grid',
            justifyContent: 'center',
          }
        }
      );
    } else if (screenWidth === 'mid') {
      setStyles(
        { ...styles,
          boxStyle: {
            ...styles.boxStyle,
            width: '65%',
          },
          appStoreImageStyle: {
            ...styles.appStoreImageStyle,
            height: '60px',
            width: '200px',
          },
          appStoreContainerStyle: {
            ...initialStyles.appStoreContainerStyle,
          }
        }
      );
    } else if (screenWidth === 'wide') {
      setStyles({ ...initialStyles });
    }
  }, [screenWidth]);

  return (
    <>
      <Seo title="Account Created Successfully!" />
      <NavBar />
      <div style={styles.pageBodyStyle}>
        <div style={styles.rowStyle}>
          <div style={styles.boxStyle}>
            <div style={{ ...styles.rowStyle, alignContent: 'center', padding: '0 0 2rem 0', }}>
              <img src="/green-check-icon.png" alt="Stethoscope" />
            </div>
            <h2 className={"font-inters text-[2rem] text-black " + (screenWidth == 'narrow' ? "text-[1.5rem]" : "")} style={{ fontWeight: '600' }}>
              Account Created Successfully!
            </h2>
            <div className="mt-6 max-w-[672px]">
              <p>You have successfully redeemed your gift!</p>
              <p className="mt-6">
                A test kit will be shipped to you within the next 3-5 days.
                In the mean time, download HeartMap to explore and view a sample report.
              </p>
              <p className="mt-6">
                Your login information are the email and password you used to create your account.
              </p>
            </div>
            {!isMobileApp && (
            <div className="mt-6 min-h-[65px]">
              Download the HeartMap app now!
              <div
                className={screenWidth === 'narrow' ? "mt-12 pr-3 pl-3" : "flex flex-row mt-12 pr-6 pl-6"}
                style={styles.appStoreContainerStyle}
              >
                <div className={screenWidth === 'narrow' ? "w-100" : "w-80"}>
                  <a href={VITE_APPLE_STORE_LINK} target="_blank" rel="noreferrer">
                    <img
                      src="/app-store-badge.png"
                      alt="Image 1"
                      style={styles.appStoreImageStyle}
                    />
                  </a>
                </div>
                <div className={screenWidth === 'narrow' ? "w-100" : "w-80"}>
                  <a href={VITE_GOOGLE_PLAY_LINK} target="_blank" rel="noreferrer">
                    <img
                      src="/google-play-badge.png"
                      alt="Image 2"
                      style={styles.appStoreImageStyle}
                    />
                  </a>
                </div>
              </div>
            </div>
            )}
          </div>

        </div>
      </div>
    </>
  );
}

export default SuccessRegistrationGift;
