import { v4 as uuidv4 } from 'uuid';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Select, { Props as SelectProps } from 'react-select';
import { Sprite } from './sprite';

export type OptionType = {
  value: string;
  label: string;
};

interface Props extends SelectProps {
  label: string
  showTopLabel?: boolean
  errorMessage?: string
  options: OptionType[]
  defaultValue: string
  value: string
  onMenuOpen?: () => void
  onMenuClose?: () => void
  onValueChange?: (value: string) => void
  [key: string]: unknown
}

export const SelectField: React.FC<Props> = React.forwardRef<any, Props>(({
  label,
  showTopLabel,
  errorMessage,
  options,
  defaultValue,
  value,
  onMenuOpen,
  onMenuClose,
  onValueChange,
  ...props
}, ref) => {
  const id = useMemo(() => `${label?.toString().toLowerCase()}-${uuidv4()}`, [label]);
  const iconId = useMemo(() => `${id}-icon`, [id]);
  const [active, setActive] = useState(false);

  useEffect(() => {
    /**
     * Used getElementById as a last resort since we cannot
     * use refs inside a component passed as a prop for
     * react-hook-form controllers.
     */
    const element = document.getElementById(iconId);
    if (!element) {
      return;
    }
    if (active) {
      element.classList.add('active');
    } else {
      element.classList.remove('active');
    }
  }, [active, iconId]);

  const open = useCallback(() => {
    if (onMenuOpen) {
      onMenuOpen();
    }
    setActive(true);
  }, [setActive, onMenuOpen]);

  const close = useCallback(() => {
    if (onMenuClose) {
      onMenuClose();
    }
    setActive(false);
  }, [setActive, onMenuClose]);

  const onChange = useCallback((data: unknown) => {
    if (onValueChange) {
      onValueChange((data as OptionType).value);
    }
    setActive(false);
  }, [onValueChange]);

  return (
    <>
      <label htmlFor={id} className="w-full">
        {showTopLabel && <span className="text-black font-medium">{label}</span>}
        <div className="relative flex flex-col mt-2">
          <Select
            ref={ref}
            styles={{
              option: (styles) => ({
                minHeight: 40,
                ...styles,
                background: '#FFFFFF',
                color: '#1D1D1D',
              }),
              control: (styles, state) => ({
                borderColor: state.isFocused ? '#003E74' : '',
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            classNames={{
              control: () => 'w-full border border-gray rounded-md p-[2px] placeholder:text-gray-medium',
              container: () => 'bg-white',
              valueContainer: () => '',
              menu: () => '',
              menuList: () => 'menu-list',
              indicatorsContainer: () => '',
              option: () => 'blur:bg-white-dirty hover:bg-white-dirty blur:text-red hover:text-red',
            }}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
            defaultValue={options.find((option) => option.value === defaultValue)}
            value={options.find((option) => option.value === value)}
            options={options}
            onFocus={open}
            onBlur={close}
            onChange={onChange}
            onMenuOpen={open}
            onMenuClose={close}
            id={id}
            {...props}
          />
          <div className="absolute top-0 right-0 h-full flex flex-col justify-center pr-[10px] pointer-events-none">
            <Sprite
              id={iconId}
              className="flipper-x w-[14px] h-[12px]"
              name="arrow-down"
            />
          </div>
        </div>
      </label>
      {errorMessage && <div className='text-error text-xs'>{errorMessage}</div>}
    </>
  );
});

SelectField.defaultProps = {
  showTopLabel: false,
  errorMessage: '',
  onMenuOpen: undefined,
  onMenuClose: undefined,
  onValueChange: undefined,
};
