import { Seo } from '../components/seo';

function AlreadySubscribedPage() {
  return (
    <>
      <Seo title="Already Subscribed" />
      <h2 className="font-inter font-semibold text-[2rem] text-blue">Already subscribed</h2>
      <div className="mt-9 max-w-[400px]">
        <h4 className="text-lg">
          You are already subscribed to this product.
        </h4>
      </div>
    </>
  );
}

export default AlreadySubscribedPage;
