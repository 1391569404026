import axios from 'axios';

export const stringifyAxiosError = (error: unknown): string => {

  if (axios.isAxiosError(error)) {
    return error?.response?.data?.message || 'Something went wrong. Please try again later or contact us at customercare@bostonheartdx.com.' ;
  }
  return 'Something went wrong. Please try again later or contact us at customercare@bostonheartdx.com.';
};

export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
