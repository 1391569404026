import React, { useEffect, useMemo, useState } from 'react';
import validator from 'validator';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth-store';
import { Button } from './button';
import { InputField } from './input-field';
import { PasswordField } from './password-field';
import { ErrorMessage } from './error-message';
import * as Sentry from "@sentry/browser";

interface Props {
  redirectUrl?: string
}

type Input = {
  code: string
  newPassword: string
  confirmNewPassword: string
}

export const ResetPasswordForm: React.FC<Props> = ({ redirectUrl }: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<Input>();
  const confirmPasswordReset = useAuthStore((state) => state.confirmPasswordReset);
  const confirmPasswordResetError = useAuthStore((state) => state.confirmPasswordResetError);
  const navigate = useNavigate();
  const [success, setSuccess] = useState<boolean>(false);
  const [newPassword, confirmNewPassword] = watch(['newPassword', 'confirmNewPassword']);

  const onSubmit = async (data: Input) => {
    try {
      await confirmPasswordReset({
        code: data.code,
        newPassword: data.newPassword,
      });
      if (redirectUrl) {
        setSuccess(true)
        navigate(redirectUrl);
      }
    } catch (err) {
      Sentry.captureException(err)

    }
  };
  const loading = useMemo(
    () => isSubmitting || success,
    [isSubmitting, success],
  );

  const confirmPasswordErrorMessage = 'Password must be the same.'
  useEffect(() => {
    const valid = validator.equals(newPassword || '', confirmNewPassword || '');
    if (!valid) {
      setError('confirmNewPassword', {
        type: 'custom',
        message: confirmPasswordErrorMessage,
      });
    } else {
      clearErrors('confirmNewPassword');
    }
  }, [newPassword, confirmNewPassword]);

  const requiredErrorMessage = 'This field is required.';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {confirmPasswordResetError && <ErrorMessage>{confirmPasswordResetError}</ErrorMessage>}
      <p className="font-inter text-gray-dark mt-6 leading-[21px]">
        We will send a one-time code via email. Please check
        your email and copy and paste the code below.
      </p>
      <fieldset className="mt-4">
        <InputField
          label="6-digit code"
          type="text"
          errorMessage={errors.code?.message}
          {...register('code', {
            required: requiredErrorMessage,
          })}
        />
      </fieldset>
      <div className="border-t border-gray-medium w-full my-4" />
      <p className="font-inter text-gray-dark mt-6 leading-[21px]">
        Before proceeding, enter your new password.
      </p>
      <fieldset className="mt-4">
        <PasswordField
          label="New Password"
          errorMessage={errors.newPassword?.message}
          {...register('newPassword', {
            required: requiredErrorMessage,
          })}
        />
      </fieldset>
      <fieldset className="mt-3">
        <PasswordField
          label="Confirm New Password"
          errorMessage={errors.confirmNewPassword?.message}
          {...register('confirmNewPassword', {
              required: requiredErrorMessage,
              validate: (value) => {
                return value === newPassword || confirmPasswordErrorMessage
              }
            })
          }
        />
      </fieldset>
      <fieldset className="flex flex-row mt-4">
        <Button
          className="flex-1"
          type="submit"
          loading={loading}
        >
          {loading ? 'Loading' : 'Submit'}
        </Button>
      </fieldset>
    </form>
  );
};

ResetPasswordForm.defaultProps = {
  redirectUrl: '/reset-password-success',
};
