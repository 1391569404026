import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Seo } from '../components/seo';
import { useSubdomainStore } from '../store/subdomain-store';
import { Button } from '../components/button';

function SuccessRegistration() {
  const { VITE_LANDING_PAGE_LINK } = import.meta.env;
  const { isOnsite } = useSubdomainStore();
  const [isMobileApp, setIsMobileApp] = useState(false);

  useEffect(() => {
    // @ts-ignore
    setIsMobileApp(window.isMobileApp === true);
  });

  return (
    <>
      <Seo title="Successful Gift Order" />
      <h2 className="font-inter font-bold text-[2rem] text-black">
        Gift Order Success!
      </h2>
      <div className="mt-2 max-w-[672px]">
        {isOnsite
          ? `Once your test has been completed, you will be notified by email that your results are available within our app. ${
            !isMobileApp
              ? 'You can download the app using the links below. Your log-in credentials will be the email and password you provided during registration.'
              : ''
          }`
          : 'You have successfully ordered a gift. You\'ll receive an order confirmation email with your order details shortly. Another email will be sent to you or your recipient for instructions on how to redeem the gift.'}
      </div>
      {!isMobileApp && (
        <div className="mt-[80px] min-h-[65px]">
          <div className="flex flex-row justify mt-5">
            <div className="w-full md:w-1/2">
              <a href={VITE_LANDING_PAGE_LINK} rel="noreferrer">
                <Button
                  className="md:flex-none flex-1"
                  type="submit"
                >
                  Back to home
                </Button>
              </a>
            </div>
            <div className="w-full md:w-1/2">
              <Link className="text-red font-medium font-inter" target="_blank" to="/product/gift">
                <Button
                  className="md:flex-none flex-1"
                  type="submit"
                >
                  Order another
                </Button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SuccessRegistration;
