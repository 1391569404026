import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCheckoutStore } from '../store/checkout-store';

/**
 * Acts as the reception page for redirects from BHD's website.
 */
function LandingPage() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const setSlug = useCheckoutStore((state) => state.setSlug);

  useEffect(() => {
    if (!slug) {
      navigate('/401');
      return;
    }
    setSlug(slug);
    navigate('/sign-up');
  }, [navigate, slug, setSlug]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

export default LandingPage;
