import { Link } from 'react-router-dom';
import { Seo } from '../components/seo';

function SuccessResetPasswordPage() {
  return (
    <>
      <Seo title="Reset Password Success" />
      <h2 className="font-inter font-semibold text-[2rem] text-black">Password has been successfully reset!</h2>
      <div className="mt-[77px]">
        <Link className="text-blue font-medium font-inter" to="/login">Back to Log in</Link>
      </div>
    </>
  );
}

export default SuccessResetPasswordPage;
