import { Seo } from '../components/seo';

function ProductNotAvailablePage() {
  return (
    <>
      <Seo title="Product Not Available" />
      <h2 className="font-inter font-semibold text-[2rem] text-blue">Unknown Product</h2>
      <div className="mt-9 max-w-[400px]">
        <h4 className="text-lg">
          Product does not exist.
        </h4>
      </div>
    </>
  );
}

export default ProductNotAvailablePage;
