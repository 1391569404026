import { ResetPasswordForm } from '../components/reset-password-form';
import { Seo } from '../components/seo';

function ResetPasswordPage() {
  return (
    <>
      <Seo title="Reset Password" />
      <div className="max-w-[311px]">
        <h2 className="font-inter font-bold text-[2rem] text-black">Change Password</h2>
        <div className="mt-6">
          <ResetPasswordForm />
        </div>
      </div>
    </>
  );
}

export default ResetPasswordPage;
