import { create } from 'zustand';
import { CountryListService } from '../services/countries/service';
import { Country } from '../services/countries/types';

const countryListService = new CountryListService();

interface CountriesStore {
  countries: Country[];
  excludedStates: string[];
  fetchCountries: {(): Promise<void>}
}

export const countryListStore = create<CountriesStore>((set) => ({
  countries: [],
  excludedStates: [],
  fetchCountries: async () => {
    const data = await countryListService.getCountries();
    set(() => ({
      countries: data.countries,
      excludedStates: data.excludedStates,
    }));
    return data;
  }
}));
