import { v4 as uuidv4 } from 'uuid';
import React, { useMemo } from 'react';

interface Props {
  label?: string
  type: string
  children?: React.ReactNode
  errorMessage?: string
  placeholder?: string
  showPlaceholder?: boolean
  showTopLabel?: boolean
  icon?: React.ReactNode
  externalRef?: React.ForwardedRef<HTMLInputElement>
  value?: string
}

export interface InputFieldProps extends Props {}

export const InputField: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(({
  label,
  type,
  errorMessage,
  placeholder,
  showPlaceholder,
  showTopLabel,
  icon,
  children,
  externalRef,
  value,
  ...props
}, ref) => {
  const id = useMemo(() => `${label?.toString().toLowerCase()}-${uuidv4()}`, [label]);

  return (
    <>
      <label htmlFor={id}>
        {showTopLabel && <span className="text-black font-medium">{label}</span>}
        <div className={`relative flex flex-col ${showTopLabel && 'mt-2'}`}>
          <input
            ref={externalRef || ref}
            className="flex-1 border border-gray rounded-md p-2 placeholder:text-gray-medium relative"
            type={type}
            id={id}
            placeholder={showPlaceholder ? placeholder || label : ''}
            value={value}
            {...props}
          />
          {icon && (
            <div className="absolute top-0 right-0 h-full flex flex-col justify-center pr-[10px] pointer-events-none">
              {icon}
            </div>
          )}
          {children && (
            <div className="absolute top-0 right-0 h-full flex flex-col justify-center pr-[10px] z-[2]">
              {children}
            </div>
          )}
        </div>
      </label>
      {errorMessage && <div className="whitespace-pre-wrap text-error text-xs">{errorMessage}</div>}
    </>
  );
});

InputField.defaultProps = {
  label: '',
  children: undefined,
  placeholder: '',
  errorMessage: '',
  showPlaceholder: true,
  showTopLabel: false,
  icon: undefined,
  externalRef: undefined,
};
