import axios from 'axios';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import { useAuthStore } from '../store/auth-store';
import { useCheckoutStore } from '../store/checkout-store';
import { useGiftingStore } from '../store/gifting-store';

interface Props {
  children: React.ReactNode
}

/**
 * Automatically redirects the user to the checkout page when available.
 */
export const CheckoutProvider: React.FC<Props> = ({ children }: Props) => {
  const navigate = useNavigate();
  const accessToken = useAuthStore((state) => state.accessToken);
  const webUrl = useCheckoutStore((state) => state.webUrl);
  const checkout = useCheckoutStore((state) => state.checkout);
  const error = useCheckoutStore((state) => state.error);
  const getGiftsOfUser = useGiftingStore((state) => state.getGiftsOfUser);
  const { giftsOfUser, validGiftCode } = useGiftingStore();

  const currentPath = window.location.pathname;
  const path406 = useMemo(
    () =>
      (currentPath === '/subscription-success'
        ? currentPath
        : '/already-subscribed'),
    [currentPath]
  );

  useEffect(() => {
    if (accessToken ) {
        checkout();
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      getGiftsOfUser();
    }
  }, [accessToken]);

  useEffect(() => {
    if (giftsOfUser?.length) {
      localStorage.clear();
      navigate('/register-gift-success');
    }
  }, [giftsOfUser]);

  useEffect(() => {
    if (currentPath === '/buy-gift-success') {
      return;
    }
    // if (giftsOfUser?.length) {
    //   navigate('/register-gift-success');
    // }
    if (axios.isAxiosError(error)) {
      console.log(currentPath)
      switch (error.request.status) {
        case 401:
          navigate('/session-expired');
          break;
        case 403:
          navigate('/session-expired');
          break;
        case 404:
          if (giftsOfUser.length) {
            localStorage.clear();
            navigate('/register-gift-success');
          } else if (!validGiftCode) {
            navigate('/product-not-available');
          }
          break;
        case 406:
          navigate(path406);
      
          break;
        default:
          navigate('redirect');
          break;
      }
    }
  }, [error]);

  useEffect(() => {
    if (validGiftCode && currentPath === '/register-gift-success') {
      return;
    }
    if (!webUrl) {
      return;
    }
    const valid = validator.isURL(webUrl, {
      protocols: ['https'],
      require_protocol: true,
      require_host: true,
    });
    if (!valid) {
      return;
    }
    navigate('redirect');
    if (giftsOfUser?.length) {
      navigate('/register-gift-success');
    } else {
      window.location.replace(webUrl);
    }
  }, [webUrl]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {children}
    </>
  );
};
