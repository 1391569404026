import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth-store';
import { Button } from './button';
import { ErrorMessage } from './error-message';
import { InputField } from './input-field';
import * as Sentry from "@sentry/browser";

interface Props {
  redirectUrl?: string
}

type Input = {
  email: string
}

export const InitiateResetPasswordForm: React.FC<Props> = ({ redirectUrl }: Props) => {
  const {
    register, handleSubmit, formState: { errors, isSubmitting },
  } = useForm<Input>();
  const initiatePasswordReset = useAuthStore((state) => state.initiatePasswordReset);
  const initiatePasswordResetError = useAuthStore((state) => state.initiatePasswordResetError);
  const navigate = useNavigate();
  const [success, setSuccess] = useState<boolean>(false);

  const onSubmit = async (data: Input) => {
    try {
      await initiatePasswordReset({ email: data.email });
      setSuccess(true)
      if (redirectUrl) {
        navigate(redirectUrl);
      }
    }
    catch (err) {
      Sentry.captureException(err)
    }
  };
  const loading = useMemo(
    () => isSubmitting || success,
    [isSubmitting, success],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {initiatePasswordResetError && <ErrorMessage>{initiatePasswordResetError}</ErrorMessage>}
      <fieldset>
        <InputField
          label="Email Address"
          type="email"
          errorMessage={errors.email?.message}
          {...register('email', {
            required: 'This field is required.',
          })}
        />
      </fieldset>
      <fieldset className="flex flex-row mt-6">
        <Button
          className="flex-1"
          type="submit"
          loading={loading}
        >
          {loading ? 'Loading' : 'Submit'}
        </Button>
      </fieldset>
    </form>
  );
};

InitiateResetPasswordForm.defaultProps = {
  redirectUrl: '/reset-password',
};
