/* eslint-disable react/no-danger */
import React, { FunctionComponent, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ClipLoader } from 'react-spinners';
import useConfigStore from '../store/config';

/**
 * TermsAndConditionModal props
 */
interface Props {}

/**
 * TermsAndConditionModal Component
 */
const TermsAndConditionModal: FunctionComponent<Props> = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { getServerConfig, serverConfig } = useConfigStore();
  useEffect(() => {
    if (isOpen) {
      getServerConfig();
    }
  }, [isOpen]);
  return (
    <>
      <span>
        I acknowledge that I have read, understood, and agree to the{' '}
        <button
          className="text-blue font-bold"
          onClick={() => setIsOpen(true)}
          type="button"
        >
          Terms and Conditions
        </button>{' '}
        of our 3rd party physician network.
      </span>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          content: {
            inset: 0,
            backgroundColor: 'transparent',
            border: 'none',
          },
          overlay: { backgroundColor: 'transparent' },
        }}
      >
        <div className="absolute inset-0 justify-center items-center flex flex-1">
          <div
            className="absolute inset-0 bg-black/[0.5] z-0"
            onClick={() => setIsOpen(false)}
            aria-hidden="true"
          />
          <div className="bg-white rounded z-[1] max-w-[800px] w-[90%] h-full max-h-[90%] justify-center items-center flex flex-1 p-3">
            {serverConfig?.isLoading ? (
              <ClipLoader color="#36d7b7" />
            ) : (
              <div className="h-full overflow-scroll">
                <h4 className="text-3xl text-blue mb-3">Terms & Conditions</h4>
                {serverConfig?.data?.termsAndCondition && (
                  <div
                    className="term-and-condition"
                    dangerouslySetInnerHTML={{
                      __html: serverConfig?.data?.termsAndCondition,
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TermsAndConditionModal;
