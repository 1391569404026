import { Link } from 'react-router-dom';
import { Seo } from '../components/seo';
import { useNavigate } from 'react-router-dom';


function SessionExpiredPage() {
  const navigate = useNavigate();
  navigate(`/login`)
  return (
    <>
      <Seo title="Token Expired" />
      <h2 className="font-inter font-semibold text-[2rem] text-blue">Session expired</h2>
      <div className="mt-9 max-w-[400px]">
        <h4 className="text-lg">
          Please login again.
        </h4>
      </div>
      <div className="mt-6">
        <Link className="text-browngray font-medium font-inter" to="/sign-up">Don&apos;t have an account?</Link>
      </div>
      <div>
        <Link className="text-browngray font-medium font-inter" to="/login">Already a member? Log in</Link>
      </div>
    </>
  );
}

export default SessionExpiredPage;
