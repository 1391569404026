import React, { useCallback, useEffect, useState } from 'react';
import { useMobile } from '../hooks/useMobile';
import { Sprite } from './sprite';
import { Link, useNavigate } from 'react-router-dom';
import { useRegistrationStore } from '../store/registration-store';

interface Props {
  children: React.ReactNode
  bannerContent?: React.ReactNode
  mobileFooterContent?: React.ReactNode
}

export const Layout: React.FC<Props> = ({ children, bannerContent, mobileFooterContent }) => {
  const isFromMobile = useMobile();

  const { VITE_PUBLISHER_NAME } = import.meta.env
  const { hasRegistered, setHasRegistered } = useRegistrationStore();
  const [currentEndpoint, setCurrentEndpoint] = useState(window.location.pathname);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentEndpoint === '/registration-success') {
      setHasRegistered(true)
    } else {
      setHasRegistered(false)
    }
  },[currentEndpoint, setHasRegistered])

  const backToRegistration = useCallback(() => {
    setHasRegistered(false);
    setCurrentEndpoint(window.location.pathname);
    navigate('/registration');
  },[navigate])

  return (
    <div className="flex md:flex-row flex-col min-h-screen">
      <div className="flex-1 bg-red md:max-h-[unset] max-h-[100px]">
        {bannerContent && (
          <div className="md:sticky md:top-0 md:h-screen h-[250px]">
            {bannerContent}
          </div>
        )}
      </div>
      <div className="flex-1 flex flex-col md:pt-11 pt-6 md:max-w-[630px] bg-white-dirty">
        <div className="flex-1 px-8">
          <div className="w-full max-w-[384px] mx-auto">
            {hasRegistered &&
              <>
                ❮ <button className="text-sm underline underline-offset-2 mb-[60px]" onClick={() => backToRegistration()}>New Registration</button>
              </>
            }
            <header>
              <Sprite name="bhd-logo" className="md:block hidden h-[40px] w-[179.89px]" />
            </header>
            <main className="flex-1 md:mt-[44px]">{children}</main>
          </div>
        </div>
        <footer className="mt-11 min-h-[65px]">
          {!isFromMobile && (
            <>
              <div className="border-t border-gray w-full" />
              <div className="py-6 text-center">
                <p className="text-gray-medium">
                  ©
                  {' '}
                  {VITE_PUBLISHER_NAME}
                </p>
              </div>
            </>
          )}
          {(mobileFooterContent && isFromMobile) && (
            <div className="md:hidden block w-full bg-white h-[120px]">
              <div className="max-w-[384px] mx-auto px-8 py-6">
                {mobileFooterContent}
              </div>
            </div>
          )}
        </footer>
      </div>
    </div>
  );
};

Layout.defaultProps = {
  mobileFooterContent: undefined,
  bannerContent: undefined,
};
