import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ResendCodeButton } from '../components/resend-code-button';
import { Seo } from '../components/seo';
import { VerificationForm } from '../components/verification-form';
import { useAuthStore } from '../store/auth-store';
import { useGiftingStore } from '../store/gifting-store';

function VerifyPage() {
  const email = useAuthStore((state) => state.email);
  const { validGiftCode } = useGiftingStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (email === '') {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <>
      <Seo title="Verify Code" />
      <h2 className="font-inter font-semibold text-[2rem] text-black">Verify Code</h2>
      { validGiftCode && <h2 className="text-green">
        You are using gift code
        {' '}
        "
        {validGiftCode}
        "
      </h2>}
      <div className="mt-9 max-w-[416px]">
        <h4 className="text-lg">
          A verification code has been sent to:
          {' '}
          {email}
        </h4>
        <div className="mt-4">
          <VerificationForm />
        </div>
        <div className="mt-10 text-center">
          <ResendCodeButton />
        </div>
      </div>
    </>
  );
}

export default VerifyPage;
