import { Seo } from '../components/seo';

function RedirectingPage() {
  return (
    <>
      <Seo title="Redirecting" />
      <h2 className="font-inter font-semibold text-[2rem] text-blue">Redirecting to checkout...</h2>
    </>
  );
}

export default RedirectingPage;
