import { Seo } from '../components/seo';

function RedirectingPageGiftCart() {
  window.location.replace(import.meta.env.VITE_GIFT_PRODUCT_URL);
    
  return (
    <>
      <Seo title="Redirecting" />
      <h2 className="font-inter font-semibold text-[2rem] text-blue">Redirecting to Gift Cart...</h2>
    </>
  );
}

export default RedirectingPageGiftCart;
