import create from 'zustand';

interface RegistrationState {
  hasRegistered?: boolean | null
  setHasRegistered: {(state: boolean | null): void}
}

export const useRegistrationStore = create<RegistrationState>((set) => ({
  hasRegistered: false,
  setHasRegistered: (hasRegistered) => set(() => ({ hasRegistered })),
}));
