import React, { FunctionComponent, useState } from 'react';
import DatePicker from '../components/DatePicker';

/**
 * ComponentsPage props
 */
interface Props {}

/**
 * ComponentsPage Component
 */
const ComponentsPage: FunctionComponent<Props> = ({ ...props }) => {
  const [cDate, setCDate] = useState<Date | undefined>();
  return (
    <div className="p-2">
      <div className="max-w-[400px]">
        <DatePicker value={cDate} onChange={(value) => setCDate(value)} />
      </div>
    </div>
  );
};

export default ComponentsPage;
