import create from 'zustand';

interface ModeState {
  mode?: string | null
  setMode: {(mode: string | null): void}
}

export const useModeStore = create<ModeState>((set) => ({
  mode: '',
  setMode: (mode) => set(() => ({ mode })),
}));
