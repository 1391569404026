// @ts-nocheck
import { FunctionComponent, useState, useEffect } from 'react';
import { GiftCodeForm } from '../components/gift-code-form';
import { Seo } from '../components/seo';
import { NavBar } from '../components/navbar';
import { Footer } from '../components/footer';

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
}

const initialPageBodyStyle = {
  backgroundColor: '#F8F8F8',
  marginTop: '50px',
  padding: '20px 0',
  backgroundImage: 'url( \'/curly-line-path.png\' )',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '25% 75%',
  maxWidth: '100%',
  overflow: 'hidden',
};

const rowStyle = {
  display: 'flex',
  flex: '1',
  // justifyContent: 'space-between',
  width: '100%',
  alignItems: 'stretch',
};

const columnStyle = {
  flex: '1',
  padding: '5px',
  boxSizing: 'border-box',
  // maxWidth: 'fit-content',
  display: 'flex',
};

const formDivStyle = {
  ...columnStyle,
  flex: '4',
  marginRight: '10px',
};

const initialImageDivStyle = {
  ...columnStyle,
  flex: '3',
  textAlign: 'center',
  padding: '50px 40px',
  maxHeight: 'min-content',
  maxWidth: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

};

const formBoxStyle = {
  backgroundColor: '#FFF',
  padding: '10px 0 10px 10px',
  margin: '50px 0px 50px 40px',
};

const imageStyle = {
  maxWidth: '100%',
  height: 'auto',
  margin: '5px',
};

const initialStyles = {
  pageBodyStyle: initialPageBodyStyle,
  rowStyle,
  columnStyle,
  formDivStyle,
  imageDivStyle: initialImageDivStyle,
  formBoxStyle,
  imageStyle,
};

/**
 * RedeemGiftPage props
 */
interface Props {}

/**
 * RedeemGiftPage Component
 */
const RedeemGiftPage: FunctionComponent<Props> = () => {
  const [screenWidth, setScreenWidth] = useState('wide');
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [styles, setStyles] = useState(initialStyles);
  const [isMobileApp, setIsMobileApp] = useState(false);

  useEffect(() => {
    localStorage.clear();
    setIsMobileApp(window.isMobileApp === true);
  });

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    setScreenWidth((screenSize.width < 754 || isMobileApp) ? 'narrow' : (screenSize.width >= 754 && screenSize.width <= 1279) ? 'mid' : 'wide');
    return (() => {
      window.removeEventListener('resize', updateDimension);
    });
  }, [screenSize]);

  useEffect(() => {
    if (screenWidth === 'narrow') {
      setStyles(
        { ...styles,
          pageBodyStyle: {
            ...styles.pageBodyStyle,
            maxWidth: '100%',
          },
          imageDivStyle: {
            // backgroundColor: 'lightgray',
            width: '120%',
            marginLeft: '-5px',
          },
          imageStyle: {
            width: '100%',
            marginLeft: '-5px',
            marginRight: '-5px',
          },
          formDivStyle: {
            ...styles.formDivStyle,
            padding: '10px',
          },
          formBoxStyle: {
            ...styles.formBoxStyle,
            margin: '10px',
            padding: '15px 20px',
          },
        }
      );
    } else if (screenWidth === 'mid') {
      setStyles(
        { ...styles,
          imageDivStyle: {
            ...styles.imageDivStyle,
            // backgroundColor: 'lightyellow',
            paddingLeft: '0',
            marginLeft: '0',
            flex: '2 3 0%',
          },
          formDivStyle: {
            ...styles.formDivStyle,
            flex: '1 1 0%',
            justifyContent: 'center',
            alignContent: 'center',
          },
          imageStyle: {
            ...styles.imageStyle,
            height: '100%',
          },
          formBoxStyle: {
            ...styles.formBoxStyle,
            padding: '50px',
            margin: '40px',
            height: 'auto',
          }
        }
      );
    } else if (screenWidth === 'wide') {
      setStyles({ ...initialStyles });
    }
  }, [screenWidth]);

  return (
    <>
      <Seo title="Redeem Gift" />
      <NavBar />
      {(screenWidth === 'narrow') && (
        <div style={styles.pageBodyStyle}>
          <div>
            <div style={styles.imageDivStyle}>
              <img src="/stethoscope.png" style={styles.imageStyle} alt="Stethoscope" />
            </div>
            <div style={styles.formDivStyle}>
              <div style={styles.formBoxStyle}>
                <h2 className="font-inter font-bold text-[2rem] text-black mb-4">
                  Redeem Your Gift
                </h2>
                <h3 className="font-inter text-[.9rem] text-black mb-4">
                  Simply enter your gift code to kickstart your HeartMap journey.
                  It's the key to unlocking a world of insights,
                  empowering you to take control of your heart health with confidence.
                </h3>
                <div className="mt-7 flex-row w-100">
                  <GiftCodeForm />
                </div>
              </div>
            </div>

          </div>
        </div>
      )}
      {(screenWidth === 'mid') && (
        <div style={styles.pageBodyStyle}>
          <div style={styles.rowStyle}>
            <div style={styles.formDivStyle}>
              <div style={styles.formBoxStyle}>
                <h2 className="font-inter font-bold text-[2rem] text-black mb-4">
                  Redeem Your Gift
                </h2>
                <h3 className="font-inter text-[.9rem] text-black mb-4">
                  Simply enter your gift code to kickstart your HeartMap journey.
                  It's the key to unlocking a world of insights,
                  empowering you to take control of your heart health with confidence.
                </h3>
                <div className="mt-7 flex-row w-100">
                  <GiftCodeForm />
                </div>
              </div>
            </div>
            <div style={styles.imageDivStyle}>
              <img src="/stethoscope.png" style={styles.imageStyle} alt="Stethoscope" />
            </div>
          </div>
        </div>
      )}
      {(screenWidth === 'wide') && (
        <div style={styles.pageBodyStyle}>
          <div style={styles.rowStyle}>
            <div style={styles.formDivStyle}>
              <div style={styles.formBoxStyle}>
                <div className="mt-10 p-10 ml-10 mr-10">
                  <h2 className="font-inter font-bold text-[2rem] text-black mb-4">
                    Redeem Your Gift
                  </h2>
                  <h3 className="font-inter text-[.9rem] text-black mb-4">
                    Simply enter your gift code to kickstart your HeartMap journey.
                    It's the key to unlocking a world of insights,
                    empowering you to take control of your heart health with confidence.
                  </h3>
                  <div className="mt-7 flex-row w-100">
                    <GiftCodeForm />
                  </div>
                </div>
              </div>
            </div>
            <div style={styles.imageDivStyle}>
              <img src="/stethoscope.png" style={styles.imageStyle} alt="Stethoscope" />
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default RedeemGiftPage;
