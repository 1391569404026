import axios from 'axios';
import dayjs from 'dayjs';
import type { AxiosInstance } from 'axios';
import type {
  CodeVerificationInput,
  ResendCodeInput,
  SignInInput,
  SignInOutput,
  SignInResponse,
  SignUpInput,
  InitiateResetPasswordInput,
  InitiateResetPasswordResponse,
  ConfirmResetPasswordInput,
  ValidateEmailInput,
  OnsiteSignUpInput,
} from './types';

export class AuthService {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: import.meta.env.VITE_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async signIn(input: SignInInput): Promise<SignInOutput> {
    const response = await this.instance.post<SignInResponse>('/auth/login', {
      email: input.email,
      password: input.password,
    });
    const { data } = response;
    return {
      id: data.accessToken.payload.sub,
      accessToken: data.idToken.jwtToken,
    };
  }

  async signUp(input: SignUpInput) {
    await this.instance.post('/auth/register', {
      email: input.email,
      password: input.password,
      firstName: input.firstName,
      middleName: input.middleName,
      lastName: input.lastName,
      birthDate: dayjs(input.birthdate).format('MM/DD/YYYY'),
      gender: input.gender,
      phone: input.phoneNumber,
      addr1: input.address1,
      addr2: input.address2,
      country: input.country,
      city: input.city,
      state: input.state,
      zipcode: input.zip,
      shareWithPhysicianNetwork: input.shareWithPhysicianNetwork,
      isNotIntendedToDiagnose: input.isNotIntendedToDiagnose,
      giftCode: input.giftCode,
    });
  }

  async onsiteSignUp(input: OnsiteSignUpInput) {
    await this.instance.post('/auth/register', {
      email: input.email,
      password: input.password,
      firstName: input.firstName,
      middleName: input.middleName,
      registeredAt: input.registeredAt,
      lastName: input.lastName,
      birthDate: dayjs(input.birthdate).format('MM/DD/YYYY'),
      gender: input.gender,
      phone: input.phoneNumber,
      shareWithPhysicianNetwork: input.shareWithPhysicianNetwork,
      isNotIntendedToDiagnose: input.isNotIntendedToDiagnose,
      event: input.event,
    });
  }

  async verifyCode(input: CodeVerificationInput) {
    await this.instance.post('/auth/confirmUser', {
      email: input.email,
      confirmationCode: input.code,
    });
  }

  async resendVerificationCode(input: ResendCodeInput) {
    await this.instance.post('/auth/resendConfirmationCode', {
      email: input.email,
    });
  }

  async initiatePasswordReset(input: InitiateResetPasswordInput): Promise<void> {
    await this.instance.post<InitiateResetPasswordResponse>('/auth/forgotPassword', input);
  }

  async confirmPasswordReset(input: ConfirmResetPasswordInput): Promise<void> {
    await this.instance.post('/auth/resetPassword', input);
  }

  async validateEmail(input: ValidateEmailInput): Promise<void> {
    await this.instance.post('/auth/validate-email', {
      email: input.email,
    });
  }
}
