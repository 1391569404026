import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import lookup from 'country-code-lookup';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';
import { Country } from 'react-phone-number-input';
import { CSSTransition } from 'react-transition-group';
import { DateTime } from 'luxon';
import PhoneInput from 'react-phone-input-2';
import {
  Gender,
  AccountDetailsInput,
  PersonalInformationInput,
  AddressInformationInput,
  SignUpInput,
} from '../services/auth/types';
import { SelectField } from './select-field';
import { CountryDropdown } from './country-dropdown';
import { RegionDropdown } from './region-dropdown';
import { Checkbox } from './checkbox';
import { useAuthStore } from '../store/auth-store';
import { Button } from './button';
import { ErrorMessage } from './error-message';
import { InputField } from './input-field';
import { PasswordField } from './password-field';
import { EllipseProgressTracker } from './ellipse-progress-tracker';
import { validatePassword } from '../utils/validators';
import { useMobile } from '../hooks/useMobile';
import { countryListStore } from '../store/countries-store';
import DatePicker from './DatePicker';
import TermsAndConditionModal from './TermsAndConditionModal';
import 'react-phone-input-2/lib/style.css';
import * as Sentry from "@sentry/browser";


const USE_LOCALSTORAGE_FOR_REG = Boolean(JSON.parse(import.meta.env.VITE_USE_LOCALSTORAGE_FOR_REG || 'false'));

interface FormPartProps<T> {
  onSubmit: { (data: T): Promise<void> };
  onBack: { (): void };
  mainFormValues: T;
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
}

interface AccountDetailsLocalInput extends AccountDetailsInput {
  confirmPassword: string;
}

const requiredErrorMessage = 'This field is required.';

const AccountDetailsForm: React.FC<FormPartProps<AccountDetailsInput>> = ({
  onSubmit,
  onBack,
  mainFormValues,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<AccountDetailsLocalInput>({
    defaultValues: {
      email: mainFormValues.email,
      password: mainFormValues.password,
      confirmPassword: mainFormValues.password,
    },
  });
  const { validateEmailError, validateEmail } = useAuthStore();

  const submit = async (data: AccountDetailsLocalInput) => {
    try {
      await validateEmail({ email: data.email });
      if (USE_LOCALSTORAGE_FOR_REG) {
        localStorage.setItem('customerRegAccountDetailsState', JSON.stringify({
          email: data.email
        }));
      }
      await onSubmit(data);
      if (USE_LOCALSTORAGE_FOR_REG) {
        localStorage.setItem('customerRegFormPage', FormPage.PERSONAL.toString());
      }
    } catch (err) {
      Sentry.captureException(err)
      console.log(err);
    }
  };

  const password = watch('password');

  // Did not use form element here to avoid premature
  // prompting of password saving, which is the default
  // behaviour of most browsers.
  return (
    <form onSubmit={handleSubmit(submit)}>
      <fieldset className="mt-5">
        {validateEmailError && (
          <ErrorMessage>{validateEmailError}</ErrorMessage>
        )}
        <div>
          <InputField
            label="Email"
            type="email"
            errorMessage={errors.email?.message}
            // value={mainFormValues.email}
            {...register('email', {
              required: requiredErrorMessage,
            })}
          />
        </div>
        <div className="mt-5">
          <PasswordField
            label="Password"
            errorMessage={errors.password?.message}
            {...register('password', {
              required: requiredErrorMessage,
              validate: (value) =>
                validatePassword(value) ||
                `Your password must contain:
  - At least 8 characters
  - At least one lowercase letter (a-z)
  - At least one uppercase letter (A-Z)
  - At least one number (0-9)  
  - At least one special character`,
            })}
          />
        </div>
        <div className="mt-5">
          <PasswordField
            label="Confirm Password"
            errorMessage={errors.confirmPassword?.message}
            {...register('confirmPassword', {
              required: requiredErrorMessage,
              validate: (value) =>
                value === password || 'Password must be the same.',
            })}
          />
        </div>
      </fieldset>
      <fieldset className="mt-11">
        <div className="border-t border-gray w-full md:block hidden" />
        <div className="flex flex-row justify-end">
          <div className="flex-1 pr-2">
            <Button
              colorClassname="md:bg-gray md:font-normal font-semibold text-black md:hidden block"
              spacingClassName="md:px-6 py-2"
              type="submit"
              onClick={() => onBack()}
            >
              Back
            </Button>
          </div>
          <Button
            className="md:flex-[5] flex-[2]"
            type="submit"
            loading={isSubmitting}
          >
            <span className="md:block hidden">Next: Account Information</span>
            <span className="md:hidden block">Next</span>
          </Button>
        </div>
      </fieldset>
    </form>
  );
};

const PersonalInformationForm: React.FC<
  FormPartProps<PersonalInformationInput>
> = ({ onSubmit, onBack, mainFormValues }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<PersonalInformationInput>({
    defaultValues: mainFormValues,
  });

  const submit = async (data: PersonalInformationInput) => {
    console.log('PersonalInformationInput', data);
    if (USE_LOCALSTORAGE_FOR_REG) {
      localStorage.setItem('customerRegPersonalInfoState', JSON.stringify({
        ...data
      }));
    }
    await onSubmit(data);
    if (USE_LOCALSTORAGE_FOR_REG) {
      localStorage.setItem('customerRegFormPage', FormPage.ADDRESS.toString());
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <fieldset>
        <div className="mt-5">
          <InputField
            label="First Name"
            type="text"
            errorMessage={errors.firstName?.message}
            {...register('firstName', {
              required: requiredErrorMessage,
            })}
          />
        </div>
        <div className="mt-5">
          <InputField
            label="Middle Name (Optional)"
            type="text"
            errorMessage={errors.middleName?.message}
            {...register('middleName')}
          />
        </div>
        <div className="mt-5">
          <InputField
            label="Last Name"
            type="text"
            errorMessage={errors.lastName?.message}
            {...register('lastName', {
              required: requiredErrorMessage,
            })}
          />
        </div>

        <div className="mt-5">
          <Controller
            control={control}
            name="birthdate"
            rules={{
              required: requiredErrorMessage,
              validate(value) {
                if (value) {
                  const diff = DateTime.now()
                    .diff(DateTime.fromJSDate(value), ['years'])
                    .toObject();
                  const yearsDiff = Math.floor(diff.years || 0);
                  if (yearsDiff < 18) {
                    return 'Laboratory testing for HeartMap is only available for adults (18 and older).';
                  }
                }
                return undefined;
              },
            }}
            render={({ field }) => (
              <DatePicker
                label="Birthdate"
                value={field.value}
                onChange={field.onChange}
                errorMessage={errors.birthdate?.message}
              />
            )}
          />
        </div>
        <div className="mt-5">
          <label className="">
            <small>Gender</small>
          </label>
          <Controller
            control={control}
            name="gender"
            rules={{
              required: requiredErrorMessage,
            }}
            render={({ field }) => (
              <SelectField
                label=""
                errorMessage={errors.gender?.message}
                options={[
                  { value: Gender.MALE, label: 'Male' },
                  { value: Gender.FEMALE, label: 'Female' },
                ]}
                defaultValue=""
                value={field.value}
                onValueChange={(value) => field.onChange(value)}
              />
            )}
          />
        </div>
      </fieldset>
      <fieldset className="mt-11">
        <div className="border-t border-gray w-full md:block hidden" />
        <div className="flex flex-row justify-end">
          <div className="flex-1 pr-2">
            <Button
              colorClassname="md:bg-gray md:font-normal font-semibold text-black"
              spacingClassName="md:px-6 py-2"
              type="submit"
              onClick={() => onBack()}
            >
              Back
            </Button>
          </div>
          <Button
            className="md:flex-[5] flex-[2]"
            type="submit"
            loading={isSubmitting}
          >
            <span className="md:block hidden">Next: Address Information</span>
            <span className="md:hidden block">Next</span>
          </Button>
        </div>
      </fieldset>
    </form>
  );
};

const AddressInformationForm: React.FC<
  FormPartProps<AddressInformationInput>
> = ({ onSubmit, onBack, mainFormValues, children }) => {
  const { signUpError } = useAuthStore();
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<AddressInformationInput>({
    defaultValues: mainFormValues,
  });

  const country = watch('country');

  const countryCode = useMemo<Country>(() => {
    const code = lookup.byCountry(country as string)?.internet as Country;
    return code;
  }, [country]);

  const submit = async (data: AddressInformationInput) => {
    console.log('AddressInformationInput', data);
    if (USE_LOCALSTORAGE_FOR_REG) {
      localStorage.setItem('customerRegAddressInfoState', JSON.stringify({
        ...data
      }));
    }
    await onSubmit(data);
    if (USE_LOCALSTORAGE_FOR_REG) {
      localStorage.setItem('customerRegFormPage', FormPage.ADDRESS.toString());
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      {children && children}
      <fieldset>
        {signUpError && <ErrorMessage>{signUpError}</ErrorMessage>}
        <div className="mt-5">
          <label>
            <small>Mobile Number</small>
          </label>
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              required: requiredErrorMessage,
              validate: (value) =>
                validator.isMobilePhone(value || '') ||
                'Phone number must be valid.',
            }}
            render={({ field }) => (

              <PhoneInput
                country="us"
                value={field.value}
                copyNumbersOnly={false}
                onChange={(value) => field.onChange(`+${value}`)}
              />
            )}
          />
          <div className="text-error text-xs">
            {errors.phoneNumber && <span>{errors.phoneNumber.message}</span>}
          </div>
        </div>
        <div className="mt-5">
          <InputField
            label="Address 1"
            type="text"
            errorMessage={errors.address1?.message}
            {...register('address1', {
              required: requiredErrorMessage,
            })}
          />
        </div>
        <div className="mt-5">
          <InputField
            label="Address 2"
            type="text"
            errorMessage={errors.address2?.message}
            {...register('address2')}
          />
        </div>
        <div className="mt-5 relative]">
          <Controller
            control={control}
            name="country"
            rules={{
              required: requiredErrorMessage,
            }}
            render={({ field }) => (
              <CountryDropdown
                label="Country"
                errorMessage={errors.country?.message}
                value={field.value}
                onValueChange={(value) => field.onChange(value)}
              />
            )}
          />
        </div>
        <div className="mt-5 relative">
          <Controller
            control={control}
            name="state"
            rules={{
              required: requiredErrorMessage,
            }}
            render={({ field }) => (
              <RegionDropdown
                label="State"
                errorMessage={errors.state?.message}
                classes="w-full border border-gray rounded-md p-2 mt-2 placeholder:text-gray-medium"
                defaultOptionLabel=" "
                country={country}
                value={field.value}
                onValueChange={(value) => field.onChange(value)}
              />
            )}
          />
        </div>
        <div className="mt-5 relative z-1">
          <InputField
            label="City"
            type="text"
            errorMessage={errors.city?.message}
            {...register('city', {
              required: requiredErrorMessage,
            })}
          />
        </div>
        <div className="mt-5 relative z-1">
          <InputField
            label="Zip Code"
            type="text"
            errorMessage={errors.zip?.message}
            {...register('zip', {
              required: requiredErrorMessage,
            })}
          />
        </div>
      </fieldset>
      <fieldset className="mt-11 gap-2 flex flex-col">
        <div>
          <Checkbox
            label={<TermsAndConditionModal />}
            errorMessage={errors.termsAndConditions?.message}
            {...register('termsAndConditions', {
              validate: (value) =>
                (value ? true : 'You must acknowledge this term.'),
            })}
          />
        </div>
        <div>
          <Checkbox
            label="I acknowledge that my personal information will be used by the laboratory according to HIPAA regulations for the performance of my lab tests and that de-identified data will be used to improve algorithms."
            errorMessage={errors.shareWithPhysicianNetwork?.message}
            {...register('shareWithPhysicianNetwork', {
              validate: (value) =>
                (value ? true : 'You must acknowledge this term.'),
            })}
          />
        </div>
        <div>
          <Checkbox
            label="I acknowledge that my test results are not intended to diagnose or treat any condition, illness or disease."
            errorMessage={errors.isNotIntendedToDiagnose?.message}
            {...register('isNotIntendedToDiagnose', {
              validate: (value) =>
                (value ? true : 'You must acknowledge this term.'),
            })}
          />
        </div>
      </fieldset>
      <fieldset className="mt-11">
        <div className="border-t border-gray w-full md:block hidden" />
        <div className="flex flex-row justify-end">
          <div className="flex-1 pr-2">
            <Button
              colorClassname="md:bg-gray md:font-normal font-semibold text-black"
              spacingClassName="md:px-6 py-2"
              type="submit"
              onClick={() => onBack()}
            >
              Back
            </Button>
          </div>
          <Button
            className="md:flex-[5] flex-[2]"
            type="submit"
            loading={isSubmitting}
          >
            Create an Account
          </Button>
        </div>
      </fieldset>
    </form>
  );
};

interface Props {
  defaultCountry?: string;
  verificationCodePageUrl?: string;
  giftCode?: string | undefined;
}

enum FormPage {
  ACCOUNT = 0,
  PERSONAL = 1,
  ADDRESS = 2,
}

export const MultipartSignUpForm: React.FC<Props> = ({
  defaultCountry,
  verificationCodePageUrl,
  giftCode,
}: Props) => {
  const navigate = useNavigate();
  const { signUp } = useAuthStore();
  const { watch, handleSubmit, setValue } = useForm<SignUpInput>({
    defaultValues: {
      country: defaultCountry,
      giftCode
    },
  });
  const [formPage, setFormPage] = useState<FormPage>(FormPage.ACCOUNT);

  const formData = watch();

  const isFromMobile = useMobile();

  const fetchCountries = countryListStore((state) => state.fetchCountries);
  const excludedStates = countryListStore((state) => state.excludedStates);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  useEffect(() => {
    console.log('formData 1!!!', formData);
    if (USE_LOCALSTORAGE_FOR_REG) {
      const storedRegAccountDetails = localStorage.getItem('customerRegAccountDetailsState');
      const storedRegPersonalInfo = localStorage.getItem('customerRegPersonalInfoState');
      const storedRegAddressInfo = localStorage.getItem('customerRegAddressInfoState');
      if (storedRegAccountDetails) {
        setValue('email', JSON.parse(storedRegAccountDetails).email);
      }
      if (storedRegPersonalInfo) {
        const parsedStoredRegPersonalInfo = JSON.parse(storedRegPersonalInfo);
        setValue('firstName', parsedStoredRegPersonalInfo.firstName);
        setValue('lastName', parsedStoredRegPersonalInfo.lastName);
        setValue('middleName', parsedStoredRegPersonalInfo.middleName);
        setValue('birthdate', new Date(parsedStoredRegPersonalInfo.birthdate));
        setValue('gender', parsedStoredRegPersonalInfo.gender);
        // setFormPage(FormPage.ADDRESS);
      }
      if (storedRegAddressInfo) {
        const parsedStoredRegAddressInfo = JSON.parse(storedRegAddressInfo);
        setValue('phoneNumber', parsedStoredRegAddressInfo.phoneNumber);
        setValue('address1', parsedStoredRegAddressInfo.address1);
        setValue('address2', parsedStoredRegAddressInfo.address2);
        setValue('country', parsedStoredRegAddressInfo.country);
        setValue('city', parsedStoredRegAddressInfo.city);
        setValue('state', parsedStoredRegAddressInfo.state);
        setValue('zip', parsedStoredRegAddressInfo.zip);
        setValue(
          'shareWithPhysicianNetwork',
          parsedStoredRegAddressInfo.shareWithPhysicianNetwork
        );
        setValue('isNotIntendedToDiagnose', parsedStoredRegAddressInfo.isNotIntendedToDiagnose);
      }
    }
    fetchCountries();
  }, [formPage]);

  useEffect(() => {
    console.log('formData 2!!!', formData);
    if (USE_LOCALSTORAGE_FOR_REG) {
      const storedRegFormPage = localStorage.getItem('customerRegFormPage');
      const storedRegAccountDetails = localStorage.getItem('customerRegAccountDetailsState');
      if (storedRegFormPage && storedRegAccountDetails) {
        setFormPage(parseInt(storedRegFormPage));
      }
    }
  }, [formData]);

  const excludedStatesDisclaimer = useCallback(() => {
    const excludedStatesCount = excludedStates.length;
    if (excludedStatesCount <= 1) {
      return excludedStates.join('');
    }
    return `${excludedStates.slice(0, -1).join(', ')}${
      excludedStatesCount > 2 ? ',' : ''
    } and ${excludedStates.slice(-1)}`;
  }, [excludedStates]);

  const submit = handleSubmit(async (data: SignUpInput) => {
    localStorage.removeItem('customerRegAccountDetailsState');
    localStorage.removeItem('customerRegPersonalInfoState');
    localStorage.removeItem('customerRegAddressInfoState');
    localStorage.removeItem('customerRegFormPage');
    await signUp(data);
    if (!isFromMobile && verificationCodePageUrl) {
      if (giftCode) {
        navigate(`${verificationCodePageUrl}?giftCode=${giftCode}`);
      } else {
        navigate(verificationCodePageUrl);
      }
    } else if (isFromMobile) {
      navigate(`${verificationCodePageUrl}?from=mobile`);
    }
  });

  const formName = useMemo(() => {
    switch (formPage) {
      case FormPage.ACCOUNT:
        return 'Account Details';
      case FormPage.PERSONAL:
        return 'Personal Information';
      case FormPage.ADDRESS:
        return 'Address Information';
      default:
        return '';
    }
  }, [formPage]);

  const transitionRef = useRef(null);

  return (
    <div className="relative">
      <div className="flex flex-row justify-between">
        <h4 className="font-semibold font-inter text-xl">{formName}</h4>
        <div className="flex flex-col justify-center">
          <EllipseProgressTracker total={3} current={formPage} />
        </div>
      </div>
      {formPage === FormPage.ACCOUNT && (
        <AccountDetailsForm
          mainFormValues={formData}
          onBack={() => {
            navigate('/login');
          }}
          onSubmit={async (data) => {
            setValue('email', data.email);
            setValue('password', data.password);
            setFormPage(FormPage.PERSONAL);
          }}
        />
      )}
      {formPage === FormPage.PERSONAL && (
        <PersonalInformationForm
          mainFormValues={formData}
          onBack={
            () => {
              setFormPage(FormPage.ACCOUNT);
              if (USE_LOCALSTORAGE_FOR_REG) {
                localStorage.setItem('customerRegFormPage', FormPage.ACCOUNT.toString());
              }
            }
          }
          onSubmit={async (data) => {
            setValue('firstName', data.firstName);
            setValue('lastName', data.lastName);
            setValue('middleName', data.middleName);
            setValue('birthdate', data.birthdate);
            console.log('birthdate', data.birthdate);
            console.log('type birthdate', typeof data.birthdate);
            setValue('gender', data.gender);
            setFormPage(FormPage.ADDRESS);
          }}
        />
      )}
      {formPage === FormPage.ADDRESS && (
        <AddressInformationForm
          mainFormValues={formData}
          onBack={() => {
            setFormPage(FormPage.PERSONAL);
            if (USE_LOCALSTORAGE_FOR_REG) {
              localStorage.setItem('customerRegFormPage', FormPage.PERSONAL.toString());
            }
          }}
          onSubmit={async (data) => {
            setValue('phoneNumber', data.phoneNumber);
            setValue('address1', data.address1);
            setValue('address2', data.address2);
            setValue('country', data.country);
            setValue('city', data.city);
            setValue('state', data.state);
            setValue('zip', data.zip);
            setValue(
              'shareWithPhysicianNetwork',
              data.shareWithPhysicianNetwork
            );
            setValue('isNotIntendedToDiagnose', data.isNotIntendedToDiagnose);
            await submit();
          }}
        >
          {/**
           * Note: These hidden inputs are here in order to trigger
           * the default behaviour browsers do, which prompts the user
           * if they want to save the password.
           */}
          <input type="hidden" name="email" value={formData.email} />
          <input type="hidden" name="password" value={formData.password} />
        </AddressInformationForm>
      )}
      {excludedStates.length > 0 && (
        <CSSTransition
          nodeRef={transitionRef}
          unmountOnExit
          in={formPage === FormPage.PERSONAL}
          timeout={300}
          classNames="fade-down"
        >
          <div className="flex flex-row mt-[20px] gap-2" ref={transitionRef}>
            <span
              className="material-symbols-outlined text-blue"
              style={{ fontSize: 20 }}
            >
              info
            </span>
            <span className="text-base text-black">
              Laboratory testing for HeartMap is not currently available in
              {' '}
              {excludedStatesDisclaimer()}
              .
            </span>
          </div>
        </CSSTransition>
      )}
    </div>
  );
};

MultipartSignUpForm.defaultProps = {
  verificationCodePageUrl: '/verify-code',
  defaultCountry: 'United States',
  giftCode: undefined
};
