import create from 'zustand';
import * as Sentry from '@sentry/browser';
import type { AxiosError } from 'axios';
import { EventsService } from '../services/event/service';

interface SubdomainState {
  isOnsite?: boolean | null;
  event?: any;
  setIsOnsite: { (state: boolean | null): void };
  setEvent: (event: string) => Promise<void>;
  error: AxiosError | unknown;
}

const eventsService = new EventsService();

export const useSubdomainStore = create<SubdomainState>((set) => ({
  isOnsite: false,
  event: null,
  error: null,
  setIsOnsite: (isOnsite) => {
    set(() => ({ isOnsite }));
  },
  setEvent: async (event: string) => {
    try {
      const result = await eventsService.getEventBySlug(event);
      if (!result) {
        throw 'Invalid conference slug.';
      }
      set(() => ({ event: result }));
    } catch (error) {
      Sentry.captureException(error);
      // eslint-disable-next-line no-console
      console.log(error);
      set(() => ({ error }));
      throw error;
    }
  },
}));
