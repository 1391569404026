import { DateObjectUnits, DateTime, Info } from 'luxon';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { OptionType, SelectField } from './select-field';

/**
 * DatePicker props
 */
interface Props {
  value?: Date;
  onChange?: (value: Date) => void;
  errorMessage?: string;
  label?: string;
}

enum selects {
  month = 'month',
  day = 'day',
  year = 'year',
}

/**
 * DatePicker Component
 */
const DatePicker: FunctionComponent<Props> = ({
  errorMessage,
  label,
  ...props
}) => {
  const [value, setValue] = useState<DateObjectUnits>();

  const timestampVal = useMemo(() => (props.value || new Date()).getTime(), []);

  useEffect(() => {
    if (props.value) {
      const luxVal = DateTime.fromJSDate(props.value);
      const { month, day, year } = luxVal;
      setValue({ month, day, year });
    }
  }, [timestampVal]);

  useEffect(() => {
    if (value) {
      const keys = Object.keys(value) as (keyof DateObjectUnits)[];
      const filteredKeys = keys.filter((e) => value[e]);

      const luxVal = DateTime.fromObject(value);
      if (filteredKeys.length === 3 && luxVal.isValid && props.onChange) {
        props.onChange(luxVal.toJSDate());
      }
    }
  }, [value]);

  const valueWithDefault = useMemo<DateObjectUnits>(() => {
    const cDate = DateTime.now();
    let { month, year, day } = value || {};
    if (!month) {
      month = cDate.month;
    }
    if (!year) {
      year = cDate.year;
    }
    if (!day) {
      day = 1;
    }
    return { month, day, year };
  }, [value]);

  const dayOptions = useMemo<OptionType[]>(() => {
    const { month, year } = valueWithDefault;
    const cDate = DateTime.fromFormat(`${month}-1-${year}`, 'L-d-yyyy');
    return new Array(cDate.daysInMonth)
      .fill(0)
      .map((_, index) => ({ value: `${index + 1}`, label: `${index + 1}` }));
  }, [valueWithDefault]);

  const yearOptions = useMemo<OptionType[]>(() => {
    const cYear = DateTime.now().year;
    return new Array(200).fill(0).map((_, index) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const value = cYear - 100 + index;
      return { value: `${value}`, label: `${value}` };
    });
  }, []);

  const monthsOptions = useMemo<OptionType[]>(
    () =>
      Info.months('long').map((monthLong, index) => ({
        value: `${index + 1}`,
        label: monthLong,
      })),
    []
  );

  const getOptions = useCallback(
    (select: selects): OptionType[] => {
      if (select === selects.month) return monthsOptions;
      if (select === selects.day) return dayOptions;
      if (select === selects.year) return yearOptions;
      return [];
    },
    [monthsOptions, dayOptions, yearOptions]
  );

  useEffect(() => {
    const cDay = dayOptions.find(
      (val) =>
        parseInt(val.value, 10) ===
        parseInt(valueWithDefault.day?.toString() || '', 10)
    );
    if (!cDay) {
      setValue((val) => ({
        ...val,
        day: parseInt(dayOptions.slice(-1)[0].value, 10),
      }));
    }
  }, [valueWithDefault.day, dayOptions]);

  const onChange = useCallback(
    (select: selects) => (_value: unknown) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const value = _value as OptionType;
      setValue((val) => ({ ...val, [select]: value.value }));
    },
    []
  );

  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.querySelector('.menu-list');
      if (selectedEl && !value?.year) {
        selectedEl.scrollTop = selectedEl.scrollHeight / 2;
      }
    }, 15);
  };

  return (
    <div>
      <label htmlFor="date-picker">
        <small>{label}</small>
      </label>
      <div className="flex flex-1 justify-center items-center gap-1 flex-col md:flex-row">
        {Object.values(selects).map((select) => (
          <SelectField
            name="date-picker"
            key={select}
            className="flex-1 flex"
            label={select.pretty()}
            options={getOptions(select)}
            defaultValue=""
            value={`${value?.[select] || ''}`}
            placeholder={select.pretty()}
            onChange={onChange(select)}
            onMenuOpen={select === selects.year ? onMenuOpen : undefined}
            isSearchable={false}
          />
        ))}
      </div>
      {errorMessage && (
        <div className="whitespace-pre-wrap text-error text-xs">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
