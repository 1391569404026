import axios from 'axios';
import type { AxiosInstance } from 'axios';
import type {
  CheckoutInput,
  CheckoutOutput,
} from './types';

export class CheckoutService {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: import.meta.env.VITE_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async checkout(input: CheckoutInput): Promise<CheckoutOutput> {
    const response = await this.instance.post<CheckoutOutput>(
      '/shopify/checkout',
      {
        productSlug: input.slug,
      },
      {
        headers: {
          Authorization: `Bearer ${input.accessToken}`,
        },
      },
    );
    return response.data;
  }
}
