import { InitiateResetPasswordForm } from '../components/initiate-reset-password-form';
import { Seo } from '../components/seo';

function ForgotPasswordPage() {
  return (
    <>
      <Seo title="Forgot Password" />
      <div className="max-w-[311px] tracking-[-0.32px]">
        <h2 className="font-inter font-bold text-[2rem] text-black leading-[-0.32px]">Forgot Password</h2>
        <p className="font-inter text-gray-dark mt-6 leading-[21px]">
          Please enter your email address so we can send
          you an email with a code to reset your password.
        </p>
        <div className="mt-6 max-w-[416px]">
          <InitiateResetPasswordForm />
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordPage;
